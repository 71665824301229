import { createContext, Dispatch, SetStateAction } from 'react';
import { ApplicationAddresses } from 'shared/api/assurant/quotePayloadTypes';
import { DynamicSchemaResponse } from 'shared/api/types';

interface ApplicationFormContextProps {
	applicationFormData: DynamicSchemaResponse,
	setApplicationFormData: Dispatch<SetStateAction<DynamicSchemaResponse>>,
	applicationAddress: ApplicationAddresses,
	setApplicationAddress: Dispatch<SetStateAction<ApplicationAddresses>>,
}

export const ApplicationFormContext = createContext<ApplicationFormContextProps>({
	applicationFormData: {
		dynamicSchema: {
			definitions: {},
			type: '',
			additionalProperties: '',
			properties: {},
		},
	},
	setApplicationFormData: () => {
		throw new Error(
			'ApplicationFormContext must be inside a Provider with a value'
		)
	},
	applicationAddress: {
		mailingAddress: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			postalCode: '',
		},
		billing: {
			address: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: '',
			},
			firstName: '',
			lastName: '',
		},
		propertyAddress: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			postalCode: '',
		}
	},
	setApplicationAddress: () => {
		throw new Error(
			'ApplicationAddress must be inside a Provider with a value'
		)
	},
});
