/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext } from 'react';
import numeral from 'numeral';

import {Box, Typography} from "@mui/material";
import {TotalAmount, TotalAmountChip} from "./styles";
import {Wrapper} from "shared/ui/Wrapper/Wrapper";
import CoverageInfo from "./components/CoverageInfo";
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import { useQuoteQuery } from 'entities/Quote/hooks/queries';

const periodsObj: Record<number, string> = {
	1: 'Annual',
	4: '4 Pay',
	11: 'Monthly'
}

const periodsDesc: Record<number, string> = {
	1: 'Best value, pay all at once today to lower your total cost',
	4: '1 down payment today',
	11: '1 down payment today'
}

const CoverageOverview = () => {
	const {formData} = useContext(FormDataContext);
	const {plan} = formData;
	const query = useQuoteQuery();
	const premiumAmount = query?.premiumDetails.premiumAmount;
	return (
		<>
			<Wrapper>
				<Typography variant="h4">Coverage Overview</Typography>
				<Box textAlign="center" sx={{mb: 2}}>
					<TotalAmount>{
						plan.price ?
							numeral(plan.installmentAmount || plan.price || 0).add(plan.fee ?? 0).format('$0,0[.]00') :
							`$${premiumAmount}`
					}</TotalAmount>
					<Typography textAlign="center" variant="subtitle2" sx={{fontSize: '20px', lineHeight: '28px', mb: 1}} textTransform="capitalize">
						{periodsObj[plan.period]}
					</Typography>
					<TotalAmountChip>Popular</TotalAmountChip>
				</Box>
				<Typography variant="subtitle2" sx={{fontWeight: 400, textAlign: 'center'}}>{periodsDesc[plan.period]}</Typography>
				<Typography textAlign="center" variant="subtitle2" sx={{fontSize: '24px', lineHeight: '28px', mb: 1}} textTransform="capitalize">
					{plan.period !== 1 && numeral(plan.price).format('$0,0[.]00')}
				</Typography>
			</Wrapper>
			<Box sx={{mt: 4}}>
				<CoverageInfo />
			</Box>
		</>
	)
}

export default CoverageOverview
