import axios, { AxiosResponse } from 'axios'
import { Buffer } from 'buffer'
import {
	ApplicationFormResponse,
	ApplicationProcessResponse,
	DynamicSchemaResponse,
	UpdateApplicationFormRequest
} from './types';
import { MicroSiteApiPrivate } from './api'

// eslint-disable-next-line max-len
const token = `0oa1x6yw8pfqwHWEv0h8:xB9QIoasywWbn__5gcoRvW1b6JWOWnLfEA6SmKJ2zY6eRFFTT7HM1kP9r6_a-vbd`;
const encodedToken = Buffer.from(token).toString('base64');

const $authApi = axios.create({
	baseURL: 'https://idm-model.assurant.com/oauth2/auswnnz3scdOHiZua0h7/v1/token',
	headers: {
		'Authorization': 'Basic ' + encodedToken,
		'Content-Type': 'application/x-www-form-urlencoded',
		'Cache-Control': 'no-cache',
	},
})
const $baseApi = axios.create({
	baseURL: 'https://housing-apis-model.assurant.com/api/globalhousing/renters/quote/v2/',
	headers: {
		accept: 'application/json; charset=UTF-8',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': '40849a93a48d43dc9c129bf26cca3059',
		'Cache-Control': 'no-cache',
		'Access-Control-Allow-Origin': '*',
	},
})
const $disclosureApi = axios.create({
	baseURL: 'https://housing-apis-model.assurant.com/api/globalhousing/contentmanagement/v1/',
	headers: {
		accept: 'application/json; charset=UTF-8',
		'Ocp-Apim-Subscription-Key': '40849a93a48d43dc9c129bf26cca3059',
		'Cache-Control': 'no-cache',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/x-www-form-urlencoded',
	},
})
const $policyApi = axios.create({
	baseURL: 'https://housing-apis-model.assurant.com/api/globalhousing/renters/policy/v2/',
	headers: {
		accept: 'application/json; charset=UTF-8',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': '40849a93a48d43dc9c129bf26cca3059',
		'Cache-Control': 'no-cache',
		'Access-Control-Allow-Origin': '*',
	},
})

export {
	$authApi,
	$baseApi,
	$policyApi,
	$disclosureApi
}

$baseApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

$policyApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

$disclosureApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

export default class ApplicationForm {
	static async getData(applicationFormId: string): Promise<AxiosResponse<DynamicSchemaResponse>> {
		return MicroSiteApiPrivate.get(
			`/api/ApplicationForms/${applicationFormId}/DynamicSchema`
		);
	}
	static async updateData({ applicationFormId, data }: UpdateApplicationFormRequest): Promise<AxiosResponse<ApplicationFormResponse>> {
		return MicroSiteApiPrivate.patch(
			`/api/ApplicationForms/${applicationFormId}`, data
		);
	}
	static async getApplicationProcess(applicationFormId: string): Promise<AxiosResponse<ApplicationProcessResponse>> {
		return MicroSiteApiPrivate.get(
			`/api/ApplicationForms/${applicationFormId}/applicationprocess`
		);
	}
	static async applicationProcessBack(applicationFormId: string): Promise<AxiosResponse<ApplicationProcessResponse>> {
		return MicroSiteApiPrivate.post(
			`/api/ApplicationForms/${applicationFormId}/applicationprocess/back`
		);
	}
}

// Address Autocomplete
export const herePlatformHeader = {
	baseURL: 'https://autocomplete.search.hereapi.com/v1/',
};

export const IXHerePlatform = axios.create(herePlatformHeader);

export const smartyStreetsAutocomplete = (query: string) => {
	return axios.get(`https://us-autocomplete-pro.api.smarty.com/lookup?key=11982700850757264&search=${query}`);
};
export const locationAutocomplete = (textBox: string) => {
	const params: string = '?' +
		'q=' + encodeURIComponent(textBox) +
		`&in=${encodeURIComponent('countryCode:USA')}` +
		'&apikey=bAxh9MfktfDXp7z-92Vxj4Z47bs0eNbfRR4XUg0ncTg';
	return IXHerePlatform.get('autocomplete' + params);
};
