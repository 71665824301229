/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { memo, useContext, useMemo } from 'react'
import numeral from 'numeral';
import dayjs from 'dayjs';

import { Box, List, ListItem, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { CoverageItem, TotalBlock } from "./styles";
import { AdditionalSmallItemIcon } from '../Accordion';
import TogglePlans from './TogglePlans';

import { CoverageDetailsParameter } from 'shared/api/assurant/quoteResponseTypes';
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';

const coverageDesc: Record<string, string> = {
	'hurricaneDeductible': 'Hurricane Deductible',
	'liabilityAmount': 'Liability Amount',
	'personalPropertyAmount': 'Your Belongings',
	'replacementCost': 'Replacement Cost',
	'waterBackup': 'Water Sewer Backup',
	'earthquake': 'Earthquake',
	'deductible': 'Deductible',
	'identityFraud': 'Fraud expense protection',
}

const yesNoText: Record<string, string> = {
	'N': 'No',
	'Y': 'Added',
}

const CoverageInfo = () => {
	const { applicationFormData } = useContext(ApplicationFormContext);
	const { query: queryState, plan } = useContext(FormDataContext);
	const coverages = queryState?.coverageDetails.parameters
	const state = queryState?.policyHolderDetails.insured.address.state
	const coverageData: string = useMemo(() => {
		const quoteInfo: string[] = Object.keys(applicationFormData.dynamicSchema.definitions).filter((key) => key.startsWith('QuoteInfo'));
		return applicationFormData.dynamicSchema.definitions[quoteInfo[0]]?.default.CoverageStartDate ?? ''
	}, [applicationFormData.dynamicSchema.definitions]);

	const renderItem = (item: CoverageDetailsParameter) => {
		const number = numeral(item.value)
		numeral.zeroFormat(item.value ?? '');
		numeral.nullFormat(item.value ?? '');
		const currency = number.format('$0,0[.]00')
		return (
			<ListItem sx={{ justifyContent: 'space-between', p: '10px 0' }} key={item.name}>
				<Box>
					<Typography>{coverageDesc[item.name]}</Typography>
				</Box>
				{item.name === 'replacementCost' && (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<AdditionalSmallItemIcon sx={{ backgroundColor: "orange.main" }}>
							<CheckIcon/>
						</AdditionalSmallItemIcon>
						<Typography fontWeight={800} textAlign="right">Included</Typography>
					</div>
				)}
				{item.name !== 'replacementCost' && item.value && (
					<Typography fontWeight={700} textAlign="right">{yesNoText[item.value] || currency}</Typography>
				)}
			</ListItem>
		)
	};

	return (
		<CoverageItem>
			<Box sx={{p:3}}>
				<Typography variant={"h4"} sx={{ mb: 1 }}>Coverage Estimator</Typography>
				<Typography variant={"subtitle2"}>Renters Insurance Summary</Typography>
				<List>
					<ListItem sx={{ justifyContent: 'space-between', p: '10px 0' }}>
						<Typography>Coverage Start Date</Typography>
						<Typography fontWeight={800} textAlign="right">
							{coverageData ? dayjs(coverageData).format('MM/DD/YYYY') : 'N/A'}
						</Typography>
					</ListItem>
					<ListItem sx={{ justifyContent: 'space-between', p: '10px 0' }}>
						<Box display="flex"  flexDirection="column">
							<Typography>Today's cost</Typography>
							<Typography fontStyle="italic">Downpayment</Typography>
						</Box>
						<Typography fontWeight={800} textAlign="right">
							{plan.downPaymentAmount && numeral(plan.downPaymentAmount).format('$0,0[.]00')}
						</Typography>
					</ListItem>
					{coverages && coverages.map((item) => {
						if (item.name === 'hurricaneDeductible'
							|| item.name === 'earthquake'
							|| item.name === 'waterBackup'
							|| item.name === 'identityFraud'
						) {
							if (item.name === 'earthquake' && state == 'CA' && item.value === 'Y') {
								return renderItem(item);
							}
							if (item.name === 'waterBackup' && item.value === 'Y') {
								return renderItem(item);
							}
							if (item.name === 'identityFraud' && item.value === 'Y') {
								return renderItem(item);
							}
						} else {
							if (coverageDesc[item.name]) {
								return renderItem(item);
							}
						}
					})}

					{coverages && coverages.map((item) => {
						if (item.name === 'hurricaneDeductible' && state == 'FL') {
							return renderItem(item);
						}
					})}
				</List>
			</Box>
			<TotalBlock>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box sx={{flex: '0 0 65%'}}>
						<TogglePlans data={queryState?.transactionDetails.payments.schedules} />
					</Box>
					<Box sx={{flex: '0 0 35%',
						textAlign: 'center'}}>
						<Typography variant="h4" color={"secondary.main"} sx={{
							color: "inherit",
							fontSize: '10px',
							lineHeight: '12px',
							fontWeight: 'normal',
							textTransform: 'capitalize',
							mb: 1,
						}} textTransform="uppercase">{!plan.installmentAmount ? "Today's cost" :  "Your next payment"}</Typography>
						<Typography color={"secondary.main"} variant={"h4"} sx={{
							textAlign: 'center',
							color: "inherit",
							fontSize: '18px',
							lineHeight: '18px',
							textTransform: 'uppercase',
							mb: 0,
						}}>{numeral(plan.installmentAmount || plan.downPaymentAmount || 0).add(plan.fee ?? 0).format('$0,0[.]00')}</Typography>
					</Box>
				</Box>
			</TotalBlock>
		</CoverageItem>
	)
}

export default memo(CoverageInfo)
