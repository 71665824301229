import { Slide, Theme } from "react-toastify";
import { ToastOptions } from 'react-toastify/dist/types';

const TOASTER_OPTIONS: ToastOptions = {
	theme: "colored" as Theme,
	position: "bottom-left",
	autoClose: 3000,
	transition: Slide,
};

const TOASTER_TOP_OPTIONS: ToastOptions = {
	theme: "colored" as Theme,
	position: "top-center",
	transition: Slide,
	autoClose: 5000,
	hideProgressBar: true,
};

export { TOASTER_OPTIONS, TOASTER_TOP_OPTIONS };
