/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApplicationForm from 'shared/api';
import { DynamicSchemaResponse } from 'shared/api/types';

export const useFormMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: ApplicationForm.getData,
		onSuccess: data => {
			const schemaData = data.data;
			queryClient.setQueryData(['applicationFormData'], schemaData);

			const assurantKeys = Object.keys(schemaData.dynamicSchema.definitions).filter((key) => key.includes('AssurantToken'));

			if (assurantKeys.length) {
				const assurantData = schemaData.dynamicSchema.definitions[assurantKeys[0]];
				const assurantJwt = assurantData.default.Token;
				if (assurantJwt) localStorage.setItem('assurantJwt', assurantJwt);
			}
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useApplicationFormQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<DynamicSchemaResponse>(['applicationFormData']);
}
