import React from 'react';
import './index.css';

import { AppRouter } from 'app/providers/router';
import { ThemeProvider } from "@mui/material";
import { theme } from 'shared/config/theme/theme';
import ErrorBoundary from 'app/providers/errorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<ErrorBoundary>
				<ToastContainer />
				<AppRouter/>
			</ErrorBoundary>
		</ThemeProvider>
	);
}

export default App;
