import React from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { StatesArray } from '../../utils/statesHash';
import { FieldError } from 'react-hook-form';

interface Props {
	value: {label: string, id: string} | undefined;
	inputValue: string;
	onChange: (event: React.SyntheticEvent, newValue: {label: string, id: string} | null) => void;
	onInputChange?: (event: React.SyntheticEvent, newValue: string) => void;
	error: FieldError | undefined
	disabled?: boolean
	getOptionLabel: (option: {label: string, id: string}) => string
}

export const AutocompleteField = (props: Props) => {
	const {value, disabled = false, inputValue, onChange, onInputChange, getOptionLabel, error} = props
	return (
		<Autocomplete
			disabled
			disablePortal
			value={value}
			inputValue={inputValue}
			onChange={onChange}
			onInputChange={onInputChange}
			id="combo-box-demo"
			getOptionLabel={option => `${option.label} - ${option.id}`}
			options={StatesArray}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						fullWidth
						variant="outlined"
						label="State"
						error={!!error}
						sx={{ '& .MuiOutlinedInput-root': {backgroundColor: '#FFFFFF' }}}
						helperText={error && error.message}
					/>
				)
			}}
		/>
	)
}
