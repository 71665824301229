/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid, Link, Popover, TextField, Typography, useMediaQuery, RadioGroup } from "@mui/material";
import RadioButton from "shared/ui/RadioButton/RadioButton";
import { Wrapper } from 'shared/ui/Wrapper/Wrapper';
import { Controller, useFormContext } from "react-hook-form";
import { DateCalendar } from '@mui/x-date-pickers';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import dayjs from 'dayjs';

const bankFields = ['payment.bankRoutingNumber', 'payment.bankAccountNumber', 'payment.bankAccountNumConfirm'];
const ccFields = ['payment.cardName', 'payment.cardNumber', 'payment.cardDate', 'payment.cardCode'];

const Payment = () => {
	const [ showOptionalDate ] = useState(false);
	const isSmall = useMediaQuery('(max-width:600px)')
	const { policyStartDate, formData: { plan } } = useContext(FormDataContext);
	const [billingDate, setBillingDate] = useState<Date>(new Date('01/17/2024'));
	const { control, setValue, watch, resetField, getValues } = useFormContext();
	const [formReset, setFormReset] = useState(false);
	const paymentOptionControl = watch('paymentType');
	const [anchorEl, setAnchorEl] = useState<EventTarget & Element | null>(null);

	const addMonth = plan.period === 4 ? 2 : 1;
	const [ nextBillingDay ] = useState(dayjs(policyStartDate).add(addMonth, 'month').format('MM/DD/YYYY'));

	useEffect(() => {
		if (paymentOptionControl === 'cc' && !formReset) {
			for(const item of bankFields) {
				resetField(item)
			}
			setFormReset(true);
		}
	}, [paymentOptionControl, resetField, formReset])

	useEffect(() => {
		if (paymentOptionControl === 'bank' && formReset) {
			for(const item of ccFields) {
				resetField(item)
			}
			setFormReset(false);
		}
	}, [paymentOptionControl, resetField, formReset])

	const handleClick = (event: React.SyntheticEvent) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const handleDate = (value: Date | null, selectionState: PickerSelectionState | undefined) => {
		value && setBillingDate(value)
		setValue('billingDate', value);
		selectionState === 'finish' && setAnchorEl(null);
	}
	const oneMonthFromNow = dayjs().add(1, 'month').format('YYYY/MM/DD');
	const endOfNextMonth = dayjs().add(1, 'month').endOf('month').format('YYYY/MM/DD');

	const handleCardNumberInput = (e: any, onChange: any) => {
		let value = e.target.value;

		if (value.length > 19) return;
		if (
			value.length === 4 ||
			value.length === 9 ||
			value.length === 14
		) {
			value += ' ';
		}
		onChange(value);
	};

	const handleCardExpiryInput = (e: any, onChange: any) => {
		let value = e.target.value;
		const code: any = e.keyCode;
		const allowedKeys = [8];
		if (allowedKeys.indexOf(code) !== -1) {
			return;
		}
		if (value.length > 5) return;
		value = value.replace(
			/[^0-9]/g, '' // To allow only numbers
		).replace(
			/^([2-9])$/g, '0$1' // To handle 3 > 03
		).replace(
			/^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
		).replace(
			/^0{1,}/g, '0' // To handle 00 > 0
		).replace(
			/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
		);
		onChange(value);
	};

	const ccExpired = (date: string) => {
		const dateArr = date.split("/");
		const exYear = +dateArr[1] + 2000;
		const exMonth = +dateArr[0] - 1;

		const exDay = new Date(exYear, exMonth + 1, 0).getDate();
		const dateObj = new Date(exYear, exMonth, exDay - 2);
		const dateNow = new Date();
		return dateNow > dateObj;
	}

	const handleCardCVC = (e: any, onChange: any) => {
		const value = e.target.value;

		if (value.length > 4) return;
		onChange(value);
	}

	const accountMatch = (value: string) => {
		return getValues('payment.bankAccountNumber') !== value;
	};

	return (
		<Box sx={{ mb: 3 }}>
			<Typography variant="h3" color="text.primary" mb={1}>Payment</Typography>
			<Wrapper>
				<Typography variant="h4">Payment Method</Typography>
				<Typography variant="subtitle2" fontWeight={400} mb={2}>
					Your one-time payment will be processed today, even if your policy is effective later.  
					We’ll then automatically charge your remaining payments and renew your policy – so that’s one less thing to worry about.
				</Typography>
				<Controller
					name="paymentType"
					control={control}
					render={({field}) => {
						return (
							<RadioGroup
								{...field}
								aria-label="icon-radio"
								sx={{ marginBottom: "1.5rem" }}
							>
								<Grid container spacing={2}>
									<Grid item md={6} sm={6} xs={12}>
										<RadioButton
											checked={field.value === 'bank'}
											value={'bank'}
											label="Bank Account"
										/>
									</Grid>
									<Grid item md={6} sm={6} xs={12}>
										<RadioButton
											checked={field.value === 'cc'}
											value={'cc'}
											label="Credit Card"
										/>
									</Grid>
								</Grid>
							</RadioGroup>
						)
					}}/>
				{paymentOptionControl === "bank" && (
					<>
						<Grid container spacing={2}>
							<Grid item md={12} sm={12} xs={12}>
								<Controller
									name="payment.bankRoutingNumber"
									control={control}
									rules={{
										required: {value: true, message: 'Routing number is required' },
										pattern: {value: /^[0-9]*$/i, message: '* Invalid Routing number'},
										minLength: {value: 9, message: 'Minimum length of routing number must be 9 digits'},
										maxLength: {value: 9, message: 'Maximum length of routing number must be 9 digits'},
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											fullWidth
											error={!!error}
											variant="outlined"
											label="Routing Number"
											helperText={error && error.message}
										/>
									}
								/>
							</Grid>
							<Grid item md={12} sm={12} xs={12}>
								<Controller
									name="payment.bankAccountNumber"
									control={control}
									rules={{
										required: {value: true, message: 'Account number is required'},
										pattern: {value: /^[0-9]*$/i, message: '* Invalid Account number'},
										maxLength: {value: 20, message: 'Maximum length of account number is 20 digits'},
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											fullWidth
											error={!!error}
											helperText={error && error.message}
											variant="outlined"
											label="Account Number"
										/>
									}
								/>
							</Grid>
							<Grid item md={12} sm={12} xs={12}>
								<Controller
									name="payment.bankAccountNumConfirm"
									control={control}
									rules={{
										required: {value: true, message: 'Thie field is required'},
										pattern: {value: /^[0-9]*$/i, message: '* Invalid Account number'},
										maxLength: {value: 20, message: 'Maximum length of account number is 20 digits'},
										validate: value => !accountMatch(value) || 'Account number does not match'
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											fullWidth
											error={!!error}
											helperText={error && error.message}
											variant="outlined"
											label="Confirm Account Number"
										/>
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
				{paymentOptionControl === "cc" && (
					<>
						<Controller
							name="payment.cardName"
							control={control}
							rules={{
								required: {value: true, message: 'Name on the Card is required' },
								pattern: {value: /^[a-z ,.'-]+$/i, message: '* Invalid name'},
							}}
							render={({ field, fieldState: { error } }) =>
								<TextField
									{...field}
									fullWidth
									error={!!error}
									helperText={error && error.message}
									variant="outlined"
									label="Name on card"
									InputProps={{
										sx: {
											"& .MuiOutlinedInput-notchedOutline": {
												borderBottomLeftRadius: 0,
												borderBottomRightRadius: 0,
												borderBottom: !error ? '0' : 'default',
											},
										},
									}}
								/>
							}
						/>

						<Controller
							name="payment.cardNumber"
							control={control}
							rules={{
								required: {value: true, message: 'Card number is required' },
								minLength: {value: 14, message: 'Minimum length of credit card number must be 14 digits'},
								pattern: {value: /^[\d\s]+$/gm, message: 'Invalid credit card number'},
							}}
							render={({ field: { onChange, ...field }, fieldState: { error } }) =>
								<TextField
									{...field}
									InputProps={{
										sx: {
											"& .MuiOutlinedInput-notchedOutline": {
												borderRadius: 0,
												borderBottom: !error ? '0' : 'default',
											},
										},
									}}
									fullWidth
									error={!!error}
									variant="outlined"
									label="Credit card number"
									onChange={(e) => handleCardNumberInput(e, onChange)}
									helperText={error && error.message}
								/>
							}
						/>
						<Grid container spacing={0}>
							<Grid item md={6} sm={6} xs={12}>
								<Controller
									name="payment.cardDate"
									control={control}
									rules={{
										minLength: {value: 4, message: 'Expiration date required minimum 4 characters'},
										maxLength: {value: 5, message: 'Expiration date required maximum 5 characters'},
										required: {value: true, message: 'Expiration date is required'},
										pattern: {
											value: /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/i,
											message: 'Expiration date is required in MM/YY format'
										},
										validate: value => !ccExpired(value) || 'Invalid Expiration date'
									}}
									render={({ field: { onChange, ...field }, fieldState: { error } }) =>
										<TextField
											{...field}
											InputProps={{
												sx: {
													"& .MuiOutlinedInput-notchedOutline": {
														borderRadius: 0,
														borderBottomLeftRadius: isSmall ? 0 : '8px',
													},
												},
											}}
											fullWidth
											error={!!error}
											variant="outlined"
											onChange={(e) => handleCardExpiryInput(e, onChange)}
											helperText={error && error.message}
											label="mm/yy"
										/>
									}
								/>
							</Grid>
							<Grid item md={6} sm={6} xs={12}>
								<Controller
									name="payment.cardCode"
									control={control}
									rules={{
										minLength: {value: 3, message: 'CVC required minimum 3 characters'},
										maxLength: {value: 4, message: 'CVC required maximum 4 characters'},
										required: {value: true, message: 'CVC is required'},
										pattern: {value: /^[0-9]*$/i, message: 'Invalid CVC'},
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											fullWidth
											variant="outlined"
											label="CVC"
											helperText={error && error.message}
											error={!!error}
											onChange={(e) => handleCardCVC(e, field.onChange)}
											InputProps={{
												sx: {
													"& .MuiOutlinedInput-notchedOutline": {
														borderRadius: 0,
														borderLeftStyle: isSmall ? 'solid' : 'none',
														borderBottomLeftRadius: isSmall ? '8px' : 0,
														borderBottomRightRadius: '8px',
														borderTopLeftRadius: 0,
														borderTopRightRadius: 0,
														borderTopStyle: isSmall ? 'none' : 'solid'
													},
												},
											}}
										/>
									}
								/>
							</Grid>
							{plan.period !==1 && <Grid item md={12}>
								<>
									<Typography variant="subtitle2" fontWeight={400} mt={2}>
										<>
											Your next Renters Insurance payment will be automatically charged on {nextBillingDay}.
											{showOptionalDate && (
												<>
													Want to be billed a different day of the month?&nbsp;
													<Link href="#" color="text.secondary" underline="hover" onClick={handleClick}>
														Select your billing day here.
													</Link>
												</>
											)}
										</>
									</Typography>
									{showOptionalDate && (
										<Popover
											id={id}
											open={open}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
										>
											<Controller
												name="billingDate"
												control={control}
												render={({ field }) =>
													<DateCalendar
														{...field}
														onChange={handleDate}
														value={billingDate || new Date()}
														minDate={new Date(oneMonthFromNow)}
														maxDate={new Date(endOfNextMonth)}
													/>
												}
											/>
										</Popover>
									)}
								</>
							</Grid>}
							
						</Grid>
					</>
				)}
			</Wrapper>
		</Box>
	)
}

export default Payment
