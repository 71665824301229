import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { getErrorMessages } from 'shared/utils/createErrorMessagesArray';
import { ErrorChip, ErrorList, ErrorListWrapper } from './styles';
import { Typography } from '@mui/material';

const CheckoutErrorsInfo = () => {
	const { formState: { errors }} = useFormContext<IFormICheckoutInput>()

	const errorsList = getErrorMessages(errors)
	if(Object.keys(errors).length <= 0) return null
	return (
		<ErrorListWrapper>
			<Typography
				variant="body1" sx={{color: '#ef5350', fontSize: '14px', mb: 1}}>Please fix the following errors before submitting</Typography>
			<ErrorList>
				{errorsList.map((item) => <li key={item}><ErrorChip >{item}</ErrorChip></li>)}
			</ErrorList>
		</ErrorListWrapper>
	)
}

export default memo(CheckoutErrorsInfo)
