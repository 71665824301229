import React, { Component, ErrorInfo, ReactNode } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactComponent as Logo } from 'assets/images/assurant_logo.svg';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false
	};

	public static getDerivedStateFromError(_: Error): State {
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error("Uncaught error:", error, errorInfo);
	}

	public render() {
		if (this.state.hasError) {
			return (
				<Container maxWidth={"xl"} >
					<Grid
						container
						spacing={0}
						sx={{minHeight: '100vh'}}
					>
						<Grid item xs={12}>
							<Box sx={{textAlign: 'center', position: 'relative'}} mt={5}>
								<Logo width={171} height={56}/>
							</Box>
							<Container maxWidth={"lg"} sx={{mt: 5}}>
								<Typography variant={"h3"} align={"center"} sx={{ mb: 0 }}>
									We're experiencing a technical issue and apologize for the inconvenience. 
									We will reach out to you in regards to your coverage.
								</Typography>
							</Container>
						</Grid>
					</Grid>
				</Container>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;