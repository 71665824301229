import React, { useContext, useEffect, useState } from 'react'
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Grid, useMediaQuery } from "@mui/material";

import DetailsList from "../DetailsList/DetailsList";
import BelongingsSlider from "../BelongingsSlider/BelongingsSlider";
import Liability from "../Liability/Liability";
import Deductible from "../Deductible/Deductible";

import { StyledButton } from "shared/ui/Button/styles";
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { IFormInput } from 'app/providers/formValues/ui/FormDataProvider';
import AdditionalProtection from "widgets/AdditionalProtection/AdditionalProtection";
import { Sidebar } from 'features/CoverageSidebar';

import { useQuoteQuery } from 'entities/Quote/hooks/queries';
import { useApplicationDataMutationQuery } from 'entities/ApplicationData/hooks/queries';
import { useApplicationProcessQuery, useProcessMutationQuery } from 'entities/ApplicationProcess/hooks/queries';
import { useFormMutationQuery, useApplicationFormQuery } from 'entities/ApplicationForm/hooks/queries';

const RentersForm = () => {
	const { setFormData, protections, policyStartDate } = useContext(FormDataContext);
	const { setApplicationFormData } = useContext(ApplicationFormContext);

	const { mutate: updateData, isSuccess } = useApplicationDataMutationQuery();
	const { mutate: getApplicationProcess, isSuccess: isProcessSuccess } = useProcessMutationQuery();
	const { mutate: fetchForm, isSuccess: fetchSuccess } = useFormMutationQuery();

	const matchesSM = useMediaQuery('(max-width:900px)');
	const [isPending, setIsPending] = useState(false);
	const queryQuote = useQuoteQuery();
	const query = useApplicationFormQuery();

	const urlSearchString = window.location.search;
	const params = new URLSearchParams(urlSearchString);
	const applicationFormId = params.get('applicationFormId') || '';

	const navigate = useNavigate();

	const applicationProcessQuery = useApplicationProcessQuery();

	const isSmall = useMediaQuery('(max-width:600px)');

	const { handleSubmit } = useFormContext<IFormInput>();

	const hurricaneDeductible = queryQuote?.coverageDetails.parameters.find((item) => item.name === 'hurricaneDeductible')?.value;

	const onSubmit = () => {
		setIsPending(true);
		void handleSubmit((data) => {
			setFormData(data);

			const applicationFormData = {
				CoverageAssurant: {
					Belongings: data.belongings,
					BillingDay: policyStartDate,
					DeductibleAssurant: `$${data.deductible}`,
					FraudExpenseProtection: !!(protections.identityFraud === "Y"),
					LiabilityAssurant: data.liability === "100000" ?
						"$100,000" : data.liability === "50000" ? "$50,000" : data.liability === "300000" ? "$300,000" : null,
					SewerBackup: !!(protections.waterBackup === "Y"),
					Earthquake: !!(protections.earthquake === "Y"),
					HurricaneDeductible: hurricaneDeductible === "1000" ?
						"$1,000" : hurricaneDeductible === "500" ? "$500" : null,
				}
			}
			updateData({
				applicationFormId,
				data: {
					attributesData: applicationFormData
				},
			});

		})()
	}

	useEffect(() => {
		if(isSuccess) {
			getApplicationProcess(applicationFormId);
		}
	}, [isSuccess]);

	useEffect(() => {
		if(fetchSuccess) {
			if (query?.dynamicSchema) {
				setApplicationFormData(query);
			}
			navigate('/checkout');
		}
	}, [fetchSuccess]);

	useEffect(() => {
		if(isProcessSuccess) {
			if (
				applicationProcessQuery?.applicationProcessStatus === "WaitingForUserInput"
			) {
				fetchForm(applicationFormId);
			} else {
				setTimeout(() => {
					getApplicationProcess(applicationFormId);
				}, 400);
			}
		}
	}, [isProcessSuccess]);

	return (
		<div>
			<form>
				<DetailsList/>
				<Liability/>
				<BelongingsSlider/>
				<AdditionalProtection/>
				<Deductible/>
				{matchesSM && <Sidebar />}
				<Grid container justifyContent={isSmall ? "center" : "end"}>
					<Grid item md={4} xs={8} sm={6}>
						<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
							CONTINUE
						</StyledButton>
					</Grid>
				</Grid>
			</form>
		</div>
	)
}

export default RentersForm
