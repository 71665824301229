import React, {ReactNode} from 'react'
import {BlockWrapper} from "./styles";

interface Props {
	children: ReactNode,
	active?: boolean,
	onClick: () => void
}

const DetailBlock = ({children, active, onClick}: Props) => {
	return (
		<BlockWrapper active={active} onClick={onClick}>
			{children}
		</BlockWrapper>
	)
}

export default DetailBlock
