import {styled} from "@mui/material/styles";
import {ListItem} from "@mui/material";

export const TotalAmount = styled('span')(({theme}) => ({
	fontFamily: 'Assistant',
	fontSize: '56px',
	lineHeight: '78px',
	fontWeight: 800,
	color: theme.palette.text.secondary
}))

export const TotalAmountChip = styled('span')(({theme}) => ({
	backgroundColor: theme.palette.text.secondary,
	color: '#fff',
	borderRadius: '15px',
	fontFamily: 'Noto Sans',
	fontWeight: 600,
	fontSize: '14px',
	lineHeight: '22px',
	padding: '1px 16px',
}))

export const BorderBlock = styled('div')(({theme}) => ({
	borderRadius: theme.custom.wrapper.borderRadius,
	padding: theme.custom.wrapper.padding,
	border: `1px solid ${theme.palette.greyBlue.main ?? "#CDD8DE"}`
}))

export const ListElement = styled(ListItem)(() => ({
	padding: '10px 0px',
	justifyContent: 'space-between'
}))
export const ListLeftText = styled('div')(({theme}) => ({
	fontSize: theme.typography.subtitle2.fontSize,
	lineHeight: theme.typography.subtitle2.lineHeight,
	color: theme.typography.subtitle2.color,
	fontWeight: 400,
}))
export const ListRightText = styled('div')(({theme}) => ({
	fontSize: theme.typography.subtitle2.fontSize,
	lineHeight: theme.typography.subtitle2.lineHeight,
	color: theme.typography.subtitle2.color,
	fontWeight: 700,
	textAlign: 'right'
}))
