import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { StyledButton } from "shared/ui/Button/styles";
import ModalWindow from "shared/ui/ModalWindow/ModalWindow";

interface Props {
	open: boolean,
	handleClose: () => void,
	propertyName: string,
	handleRemove: () => void
}

const RemoveForm = ({ open, propertyName, handleClose, handleRemove }: Props) => {
	return (
		// eslint-disable-next-line max-len
		<ModalWindow open={open} handleClose={handleClose} header={<Typography variant="h3" textAlign="center" mb={0}>Remove this {propertyName}</Typography>}>
			<Grid container item md={12} justifyContent="center" spacing={2}>
				<Grid item md={12} sm={12} xs={12}>
					<Typography variant="body1" mb={0} sx={{ textAlign: 'center', fontSize: '1rem' }}>
						Removing this {propertyName} means they won't be covered under this policy.
					</Typography>
				</Grid>
				<Grid container item spacing={2} justifyContent="center">
					<Grid item md={6} sm={12} xs={12} justifyContent="center" flexDirection="column" display="flex">
						<StyledButton onClick={handleRemove} sx={{ mt: 2 }}>Remove {propertyName}</StyledButton>
						<Button onClick={handleClose}sx={{ color: "text.secondary", textAlign: 'center' }}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</ModalWindow>
	)
}

export default RemoveForm
