import {styled} from "@mui/material/styles";
import Button from "@mui/lab/LoadingButton";

export const StyledButton = styled(Button)(({ theme}) => ({
	width: '100%',
	padding: '8px',
	borderRadius: '26px',
	margin: '10px -1px',
	border: '1px solid transparent !important',
	position: 'relative',
	background: '#fff',
	fontWeight: 700,
	fontSize: '14px',
	color: theme.palette.text.primary,
	boxSizing: 'border-box',
	userSelect: 'none',
	transition: 'background-color 0.3s linear, color 0.1s linear',
	"&:hover": {
		transition: 'background-color 0.3s linear, color 0.1s linear',
		backgroundColor: theme.palette.gradient.main,
		color: theme.palette.secondary.main,
		border: 0,
	},
	'&::before': {
		content: "''",
		position: 'absolute',
		top: -1,
		right: -1,
		bottom: -1,
		left: -1,
		zIndex: -1,
		margin: '-1px',
		borderRadius: '26px',
		backgroundImage: theme.palette.gradient.main,
	}
}))
