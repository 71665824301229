/* eslint-disable indent */
export const getDwellingType = (PropertyType: string) => {
	switch (PropertyType) {
		case 'Apartment/Condo':
			return 'A';
		case 'Single Family Home':
			return 'S';
		case 'Townhome':
			return 'T';
		case 'Dormitory/Student Housing':
			return 'D';
		default:
			break;
	}
};

export const formatPhoneNumber = (value: string) => {
	if (!value) return value;
	const phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;
	if (phoneNumberLength < 4) {
		return phoneNumber;
	}
	if (phoneNumberLength < 7) {
		return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
	}
	return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}
