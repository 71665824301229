/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApplicationForm from 'shared/api';
import { ApplicationFormResponse } from 'shared/api/types';

export const useApplicationDataMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: ApplicationForm.updateData,
		onSuccess: data => {
			const applicationData = data.data;
			queryClient.setQueryData(['application'], applicationData);
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useApplicationDataQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<ApplicationFormResponse>(['application']);
}
