import React from 'react'
import {StepItem, StepWrapper} from "./styles";
import {Grid} from "@mui/material";
import {useLocation} from "react-router-dom";

const Steps = () => {
	const location = useLocation();
	const { pathname } = location;

	const splitLocation = pathname.split("/");
	return (
		<StepWrapper>
			<Grid container spacing={2} alignContent="center" justifyContent="center">
				<Grid item md={6}>
					<Grid container spacing={2} alignContent="center" justifyContent="center">
						<Grid item md={3} sm={3} xs={3}>
							<StepItem active={false}>Questions</StepItem>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<StepItem active={splitLocation[1] === ""}>Quote</StepItem>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<StepItem active={splitLocation[1] === "checkout"}>Checkout</StepItem>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<StepItem active={splitLocation[1] === "summary"}>Review</StepItem>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</StepWrapper>
	)
}

export default Steps
