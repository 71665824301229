/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import UserInformation from "./components/UserInformation";
import Questions from "./components/Questions";
import AdditionalParties from "./components/AdditionalParties";
import PropertyAddress from "./components/PropertyAddress";
import Billing from "./components/Billing";
import Payment from "./components/Payment";
import { usePolicyErrorQuery } from 'entities/Policy/hooks/queries';
import { Typography } from "@mui/material";
import { CheckoutFormProps } from 'shared/config/types/checkout';

const CheckoutForm = ({isError}: CheckoutFormProps) => {
	const policyErrorQuery: any = usePolicyErrorQuery();

	return (
		<div>
			<form>
				<UserInformation/>
				<Questions/>
				<AdditionalParties />
				<PropertyAddress />
				<Payment />
				<Billing />

				{isError && policyErrorQuery?.errorDetails.error.errors.map((item: any) => (
					<Typography variant="subtitle2" fontWeight={400} color={"error.main"} >
						{item.description}
					</Typography>
				))}
			</form>
		</div>
	)
}

export default CheckoutForm
