import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')({
	right: 0,
	bottom: 0,
	top: 0,
	left: 0,
	position: 'fixed',
	background: 'rgba(255,255,255,0.7)',
});

export const BootstrapDialog = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	position: 'fixed',
	maxWidth: '600px',
	minWidth: '300px',
	background: '#ffffff',
	borderRadius: '4px',
	maxHeight: 'calc(100% - 64px)',
	boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px',
});
