import { $baseApi } from '../index';
import { AxiosResponse } from 'axios';
import { QuoteResponse } from './quoteResponseTypes';
import { QuotePayload } from './quotePayloadTypes';
import dayjs from 'dayjs';

export const mockPayload: QuotePayload = {
	"transaction": {
		"clientId": "RTW",
		"productType": "RI",
		"jobNumber": "000932",
		"policyEffectiveDate": dayjs().add(1, 'day').format('YYYY-MM-DD'),
	},
	"policyHolder": {
		"insured": {
			"address": {
				"address1": "123 Main Street",
				"address2": "",
				"city": "Anytown",
				"state": "TX",
				"postalCode": "73301"
			}
		}
	},
	"underWriting": {
		"questions": [
			{
				"questionName": "isMasonryConstruction",
				"answer": "N"
			},
			{
				"questionName": "mustHaveInsurance",
				"answer": "Y"
			},
			{
				"questionName": "hadAnimalInjury",
				"answer": "N"
			},
			{
				"questionName": "leaseEffectiveDate",
				"answer": ""
			}
		]
	},
	"coverage": {
		"parameters": [
			{
				"name": "earthquake",
				"value": "N"
			},
			{
				"name": "identityFraud",
				"value": "N"
			},
			{name: "iuiType", value: "N"},
			{
				"name": "liabilityAmount",
				"value": "100000"
			},
			{
				"name": "personalPropertyAmount",
				"value": "20000"
			},
			{
				"name": "petDamage",
				"value": "N"
			},
			{
				"name": "replacementCost",
				"value": "Y"
			},
			{
				"name": "waterBackup",
				"value": "Y"
			},
			{
				"name": "deductible",
				"value": "250"
			}
		]
	}
}
export default class Quote {
	static async postRentersQuote(data: QuotePayload): Promise<AxiosResponse<QuoteResponse>> {
		return $baseApi.post(``, data)
	}
}
