import React from 'react'
import { Grid } from "@mui/material";
import { StyledButton } from "shared/ui/Button/styles";
import ModalWindow from 'shared/ui/ModalWindow/ModalWindow';

interface Props {
	open: boolean,
	handleClose: () => void
}

const ReadMore = ({ open, handleClose }: Props) => {
	return (
		<ModalWindow open={open} handleClose={handleClose} header="Interested party">
			<Grid container item md={12} spacing={2} justifyContent="center">
				<Grid item md={12}>
					You understand that if we have a business relationship with your landlord/property
					management
					company,
					we may disclose your insurance policy information to your landlord/property management
					company for purposes related to the insurance obligations under your lease. Further, we may
					add your
					landlord/property
					management company as the "interested party" on the insurance policy if your lease
					requires it. Disclosed Insurance policy information may include: insured name and address,
					policy
					term,
					policy number,
					annual premium, coverage amount and coverage type
				</Grid>
				<Grid item md={2} textAlign="center">
					<StyledButton variant="outlined" onClick={handleClose}>CLOSE</StyledButton>
				</Grid>
			</Grid>
		</ModalWindow>
	)
}

export default ReadMore
