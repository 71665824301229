import React from 'react'
import { Grid, Typography } from "@mui/material";
import ModalWindow from "shared/ui/ModalWindow/ModalWindow";

interface ShortMessageServiceProps {
	open: boolean;
	handleClose: () => void;
}

const ShortMessageService = ({handleClose, open}: ShortMessageServiceProps) => {
	return (
		<ModalWindow
			open={open}
			handleClose={handleClose}
			header={<Typography variant="h4" mb={0}>
				Short Message Service
			</Typography>}
		>
			<Grid container item md={12} justifyContent="center" spacing={2}>
				<Grid item md={12}>
					<Typography variant="body1" mb={2}>
						I would like to receive informational texts from Assurant regarding my policy at the cell phone number 
						I provide below. Informational texts may include, but are not limited to: any information regarding my policy,
						notices, cancellation, non-renewal, claims, billing and general customer service. 
						The frequency and type of informational texts will be determined by Assurant. 
						I must inform Assurant if my cell phone number changes. I may choose to stop receiving informational 
						texts anytime by replying STOP. Standard message and data rates apply.
					</Typography>
				</Grid>
			</Grid>
		</ModalWindow>
	)
}

export default ShortMessageService
