import React from 'react'
import { Box, Container, Grid, Typography } from "@mui/material";
import { DefaultList } from "./styles";
import Footer from "widgets/Footer/Footer";
import { ReactComponent as Logo } from 'assets/images/rein_logo.svg';

const ErrorPage = () => {
	return (
		<Container maxWidth={"xl"} sx={{ mt: 5 }}>
			<Box sx={{ textAlign: 'center', position: 'relative' }} mt={5}>
				<Logo width={171} height={56}/>
			</Box>
			<Grid
				container
				spacing={0}
				sx={{ minHeight: '100vh' }}
			>
				<Grid item xs={12}>
					<Container maxWidth={"md"} sx={{ mt: 5 }}>
						<Typography variant={"h4"} align={"center"} fontWeight={700} fontSize={36} color="green.main" lineHeight={"44px"}>
							We're sorry, but we're unable to provide coverage for you at this time.
						</Typography>
						<Typography variant={"h4"} align={"center"} color="green.main">Notice of Adverse Underwriting
							Decision</Typography>
						<Box>
							<Typography variant={"body1"}>If you have been declined insurance coverage, it is because of the
								answer(s) you have provided to the question(s) on your application for insurance. We have based this
								decision solely on the information you have provided on your application.</Typography>
							<Typography variant={"body2"} mt={2}>ACCESS TO RECORDED PERSONAL INFORMATION</Typography>
							<Typography variant={"body1"} mt={2}>Upon your written request and submission of proper identification
								<DefaultList>
									<li>Within 30 business days of the receipt of your request to access to your recorded personal
										information, Assurant will inform you of the nature and substance of the recorded personal
										information in writing.
									</li>
									<li>You have the right to see and copy personal information in person or obtain a copy by mail.
										The
										information must be reasonably described by you and reasonably locatable and retrievable by
										us.Any
										information we provide you will be in plain language.
									</li>
									<li>If recorded, we will disclose the identity of those persons or institutional sources who
										gave us
										information within two (2) years prior to your request. If not recorded, we will disclose
										the names
										of those to whom such information is normally disclosed.
									</li>
								</DefaultList>
								Assurant shall provide medical record information supplied by a medical care institution or medical
								professional, along with the identity of the medical professional or medical institution which
								provided the information, either directly to you or to a medical professional designated by you,
								which is licensed to provide medical care with respect to the condition to which the information
								relates. Assurant will notify you if it elects to disclose the information to a medical professional
								designated by you. For CA and MA residents mental health record information shall be supplied
								directly to the individual only with the approval of the qualified professional person with
								treatment responsibility for the condition to which the information relates.</Typography>
							<Typography variant={"body2"} mt={2}>REQUEST TO CORRECT, AMEND, OR DELETE RECORDED PERSONAL
								INFORMATION</Typography>
							<Typography variant={"body1"} mt={2}>Within 30 business days from the date of receipt of your written
								request to correct, amend, or delete any recorded personal information, we must:</Typography>
							<Typography variant={"body1"} mt={2}>1. Correct, amend
								or delete the portion of the recorded personal information in dispute; or</Typography>
							<Typography variant={"body1"} mt={2}>2. Notify you of our
								refusal to make the correction, amendment or deletion, and the reason(s), for the refusal, and your
								right to file a statement if you disagree.</Typography>
							<Typography variant={"body1"} mt={2}>If we refuse to make a correction, amendment or
								deletion</Typography>
							<Typography variant={"body1"} mt={2}>1. You have the right to file a concise statement with us. Your
								statement: (a) must set forth what
								you believe to be the correct, relevant, or fair information and, (b) explain why you disagree with
								our refusal. </Typography>
							<Typography variant={"body1"} mt={2}>2. We will file your statement with any disputed personal
								information and make it
								accessible so that anyone reviewing the information will be cognizant of your
								statement.</Typography>
							<Typography variant={"body1"} mt={2}>3. Furthermore, your statement will be with any subsequent
								disclosure</Typography>
							<Typography variant={"body1"} mt={2}>If the information is
								corrected, amended or deleted, the changes will be in writing and provided to you, any person who
								may have received the incorrect information within the preceding two (2) years, anyinsurance-support
								organization that received the incorrect information within the preceding seven years, and any
								insurance support organization that furnished the personal information that has been corrected,
								amended or deleted.</Typography>
							<Typography variant={"body1"} mt={2}>If you would like additional information concerning this action,
								you have 180 days to submit a writtenrequest to:</Typography>
							<Typography variant={"body1"}>Assurant®</Typography>
							<Typography variant={"body1"}>Privacy Office</Typography>
							<Typography variant={"body1"}>Adverse Underwriting Decision</Typography>
							<Typography variant={"body1"}>11222 Quail Roost Drive</Typography>
							<Typography variant={"body1"}>Miami, FL 33157-6596</Typography>
						</Box>
					</Container>
				</Grid>
			</Grid>
			<Footer/>
		</Container>
	)
}

export default ErrorPage
