import {styled} from "@mui/material/styles"

export const CheckboxItem = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center'
}));

export const DropDownMenu = styled('div')(() => ({
	fontSize: '14px',
	background: '#ffffff',
	textTransform: 'uppercase',
	border: '1px solid #b9b9c9',
	position: 'absolute',
	zIndex: 9,
	width: '100%',
	boxSizing: 'border-box',
	top: '100%',
}));

export const DropDownMenuElement = styled('div')(() => ({
	padding: '5px',
	cursor: 'pointer',
	'&:hover': {
		transition: '0.2s linear',
		backgroundColor: 'rgba(141, 222, 255, 0.39)',
	}
}))
