import { type RouteObject } from 'react-router-dom'
import { AppRoutes, RoutePath } from 'shared/config/routeConfig/routeConfig'
import CheckoutPage from "pages/CheckoutPage/CheckoutPage";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import QuotePage from "pages/QuotePage/QuotePage";
import HomePage from "pages/HomePage/HomePage";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import ConfirmationPage from "pages/ConfirmationPage/ConfirmationPage";
import SummaryPage from "pages/SummaryPage/SummaryPage";

export const routeConfig: Partial<Record<AppRoutes, RouteObject>> = {
	[AppRoutes.MAIN]: {
		path: RoutePath[AppRoutes.MAIN],
		element: <HomePage/>,
		children: [
			{
				path: RoutePath[AppRoutes.CHECKOUT],
				element: <CheckoutPage/>,
			},
			{
				element: <QuotePage/>,
				index: true
			}
		]
	},
	[AppRoutes.SUMMARY]: {
		path: RoutePath[AppRoutes.SUMMARY],
		element: <SummaryPage />
	},
	[AppRoutes.CONFIRMATION]: {
		path: RoutePath[AppRoutes.CONFIRMATION],
		element: <ConfirmationPage />
	},
	[AppRoutes.ERROR]: {
		path: RoutePath[AppRoutes.ERROR],
		element: <ErrorPage/>
	},
	[AppRoutes.NOT_FOUND]: {
		path: RoutePath[AppRoutes.NOT_FOUND],
		element: <NotFoundPage/>
	}
}
