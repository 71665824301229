import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

export const LoaderElement = styled('div')(({theme}) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',

}))
export const Spinner = styled(CircularProgress)(({theme}) => ({
	color: theme.palette.green.main
}))

export const LoaderWrapper = styled('div', { shouldForwardProp: (propName) => propName !== 'color' })<{
	color?: string
}>(({ color }) => ({
	display: 'flex',
	width: '100%',
	height: '100%',
	position: 'absolute',
	left: 0,
	top: 0,
	backgroundColor: color ?? 'rgb(255 255 255)',
	zIndex: 9,
}))
