import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

export const ModalItem = styled(Box)(({theme}) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: theme.palette.secondary.main,
	padding: '0',
}))
