/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApplicationForm from 'shared/api';
import { BackButtonResponse } from 'shared/api/types';

export const useFormBackMutationQuery = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: ApplicationForm.applicationProcessBack,
		onSuccess: data => {
			const responseData = data.data;
			queryClient.setQueryData(['applicationFormId'], responseData);
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useApplicationBackFormQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<BackButtonResponse>(['applicationFormId']);
}
