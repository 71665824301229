/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react'
import { Controller, useFormContext } from "react-hook-form";
import dayjs from 'dayjs';

import { Grid, TextField, Typography } from "@mui/material";
import { Wrapper } from 'shared/ui/Wrapper/Wrapper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { formatPhoneNumber } from 'shared/utils/helpers';

const UserInformation = () => {
	const { control, setValue, setError, clearErrors, watch } = useFormContext();
	const eighteenYearsAgo = new Date(dayjs().subtract(18, 'year').format('YYYY/MM/DD'));

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.name as keyof IFormICheckoutInput, formatPhoneNumber(e.target.value));
		clearErrors('phoneNumber');
	};
	const onDateChange = (value: any) => {
		setValue("dateOfBirth", value);
		if (value > eighteenYearsAgo) {
			setError('dateOfBirth', {
				type: 'server error',
				message: "Date Of Birth cannot be less than 18 years",
			})
		} else {
			clearErrors('dateOfBirth');
		}
	};

	const firstNameWatcher: string = watch('firstName');
	const lastNameWatcher: string = watch('lastName');

	useEffect(() => {
		const insuredNameTotal = firstNameWatcher?.length + lastNameWatcher?.length;
		if (insuredNameTotal > 29) {
			setError('fullName', {
				type: 'server error',
				message: "Insured Name in total has to be max 29 chars.",
			})
		} else {
			clearErrors('fullName');
		}
	}, [firstNameWatcher, lastNameWatcher]);

	return (
		<Wrapper>
			<Typography variant="h4">Your Information</Typography>
			<Grid container spacing={3}>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="firstName"
						rules={{ required: {value: true, message: 'First Name is required' } }}
						control={control}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="First name"
								error={!!error}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="lastName"
						rules={{ required: {value: true, message: 'Last Name is required' } }}
						control={control}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Last name"
								error={!!error}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				{/* <Grid item md={6} sm={12} xs={12}>
					<Controller
						name="fullName"
						rules={{ required: {value: true, message: 'Full Name is required' } }}
						control={control}
						render={({ field }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Full name"
								disabled
							/>
						}
					/>
				</Grid> */}
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="phoneNumber"
						rules={{
							pattern: {value: /^[0-9]{3}[-][0-9]{3}[-][0-9]{4}$/i, message: 'Invalid phone number'},
							required: {value: true, message: 'Phone number is required' }
						}}
						control={control}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label={"Phone number"}
								error={!!error}
								onChange={onInputChange}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="dateOfBirth"
						control={control}
						rules={{
							required: {value: true, message: 'Date of birth is required' }
						}}
						render={({ field, fieldState: {error} }) => {
							return <DatePicker
								{...field}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								value={field.value || null}
								maxDate={eighteenYearsAgo}
								label="Date of Birth"
								sx={{ width: '100%' }}
								format={"MM/dd/yyyy"}
								onChange={onDateChange}
								slotProps={{
									textField: {
										helperText: error && error.message,
										error: !!error
									},
								}}
								disableFuture
							/>
						}
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="email"
						control={control}
						rules={{
							required: {value: true, message: 'Email is required' }
						}}
						render={({ field, fieldState: {error} }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Email"
								error={!!error}
								helperText={error && error.message}/>}
					/>
				</Grid>
				<Grid item md={12} sm={12} xs={12}>
					<Controller
						name="fullAddress"
						control={control}
						disabled
						render={({ field }) =>
							<TextField {...field} fullWidth variant="outlined" label={"Full Address"}/>}
					/>
				</Grid>
			</Grid>
		</Wrapper>
	)
}

export default UserInformation
