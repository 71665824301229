/* eslint-disable max-len */
import { Box, Grid, Input, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { StyledSlider } from "./styles";
import { useFormContext } from "react-hook-form";
import { IFormInput } from "app/providers/formValues/ui/FormDataProvider";
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import numeral from 'numeral';

function valuetext(value: number) {
	return `${value}`;
}

const BelongingsSlider = () => {
	const number = numeral()
	const initialRef = useRef(false)
	const { defaultQuery, setInitialPayloadData } = useContext(FormDataContext)
	const belongingChosen = defaultQuery?.coverageDetails.parameters.find((item) => item.name === 'personalPropertyAmount')
	const defaultPersonalPropertyAmount = defaultQuery?.coverageDetails.parameters.find((item) => item.name === 'personalPropertyAmount')?.value;
	const { setValue: setFormValue } = useFormContext<IFormInput>()
	const [value, setValue] = useState(Number(belongingChosen?.value || defaultPersonalPropertyAmount));
	const propertyValues = useMemo(() => {
		const defaultData = { min: 5000, max: 95000, step: 5000 }
		if (!defaultQuery) return defaultData
		return defaultQuery?.coveragesCue.parameters.reduce<Record<'min' | 'max' | 'step', number>>((accum, item) => {
			if (item.name === 'personalPropertyMin' && typeof item.value === 'string') {
				accum['min'] = Number(item.value)
			} else if (item.name === 'personalPropertyMax' && typeof item.value === 'string') {
				accum['max'] = Number(item.value)
			} else if (item.name === 'personalPropertyIncrement' && typeof item.value === 'string') {
				accum['step'] = Number(item.value)
			}
			return accum
		}, defaultData)
	}, [defaultQuery]);
	const [commonValue, setCommonValue] = useState<string | number>('');

	useEffect(() => {
		if (defaultPersonalPropertyAmount && !commonValue) {
			setCommonValue(defaultPersonalPropertyAmount);
		}
	}, [defaultPersonalPropertyAmount]);

	useEffect(() => {
		if (belongingChosen?.value && value !== Number(belongingChosen?.value)) {
			setValue(Number(belongingChosen?.value));
		}
	}, [belongingChosen]);

	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		if (!Array.isArray(newValue)) {
			setValue(newValue);
		}
	};
	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (Number(event.target.value) < propertyValues.min) {
			setValue(5000);
		} else if (Number(event.target.value) > propertyValues.max) {
			setValue(95000);
		} else {
			const roundedValue = Math.round(Number(event.target.value) / 5000) * 5000;
			setValue(event.target.value === '' ? 0 : roundedValue);
		}
	};

	const handleBlur = () => {
		if (value < propertyValues.min) {
			setValue(propertyValues.min);
		} else if (value > propertyValues.max) {
			setValue(propertyValues.max);
		}
	};

	useEffect(() => {
		setFormValue('belongings', value)
		if(!initialRef.current) {
			initialRef.current = true
			return
		}
		setInitialPayloadData((prevState) => {
			const newParametersData = prevState.coverage.parameters.map((item) => {
				if(item.name === 'personalPropertyAmount') {
					return {...item, value: value.toString()}
				}
				return item
			})
			return {
				...prevState,
				coverage: {
					parameters: newParametersData
				}
			}
		})
	}, [value, setFormValue]);

	return (
		<Box sx={{ mb: 4 }}>
			<Box sx={{ mb: 3 }}>
				<Typography variant="subtitle2" sx={{ mb: 2 }}>Your Belongings</Typography>
				<Typography>How much would it cost to
					replace your belongings (e.g., furniture, clothes, electronics)? Select your coverage based on your
					answer. The amount you choose can cover a roommate's belongings, too. Just be sure to add them to
					your policy at checkout. Move the slider or enter an amount to choose as much or as little coverage
					as you need.</Typography>
			</Box>
			<Grid container spacing={3} alignItems="center">
				<Grid item md={7} sm={12} xs={12}>
					<StyledSlider
						defaultValue={Number(defaultPersonalPropertyAmount)}
						step={propertyValues.step}
						max={propertyValues.max}
						min={propertyValues.min}
						marks={[
							{
								value: propertyValues.min,
								label: number.set(propertyValues.min).format('$0,0')
							},
							{
								value: propertyValues.max,
								label: number.set(propertyValues.max).format('$0,0')
							}
						]}
						value={value}
						sx={{ backgroundColor: 'linear-gradient(to right bottom, #24CC71, #539DE1)' }}
						aria-label="Default"
						valueLabelDisplay="auto"
						getAriaValueText={valuetext}
						onChange={handleSliderChange}/>
				</Grid>
				<Grid item md={3} sm={8} xs={8}>
					<Input
						value={value}
						size="small"
						onChange={handleInputChange}
						onBlur={handleBlur}
						fullWidth
						sx={{
							borderColor: 'greyBlue.main',
							borderWidth: 1,
							borderStyle: 'solid',
							padding: '12px 16px !important'
						}}
						inputProps={{
							step: propertyValues.step,
							min: propertyValues.min,
							max: propertyValues.max,
							type: 'number',
							'aria-labelledby': 'input-slider',
						}}
					/>
				</Grid>
				{value === Number(commonValue) && (
					<Grid item md={2} sm={4} xs={4}>
						<Typography align="center" color="darkGrey.primary">Most common</Typography>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

export default BelongingsSlider
