import {styled} from "@mui/material/styles";
import {Collapse} from "@mui/material";

export const ViewDetails = styled("div", {shouldForwardProp: (propName) => propName !== 'open' })<{open: boolean}>(({ open}) => ({
	display: 'flex',
	alignItems: 'center',
	marginTop: '10px',
	textAlign: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	marginBottom: '10px',
	"& svg": {
		transition: 'transform 0.2s linear',
		transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
	}
}))

export const CollapseAnimation = styled(Collapse)(() => ({
	'&.MuiCollapse-entered' : {
		height: '100% !important',
		transition:'none',
		'& .MuiCollapse-wrapper': {
			height: '100%'
		},
		'& .MuiCollapse-wrapperInner': {
			height: '100%'
		}
	},
}))

export const CardRibbon = styled('div', { shouldForwardProp: (propName) => propName !== 'sm' })<{
	sm: boolean
}>(({ theme, sm }) => ({
	position: 'absolute',
	overflow: 'hidden',
	top: '-10px',
	left:  sm ? 'auto' : '115px',
	right: sm ? '-9px' : 'auto',
	width: '114px',
	height: '112px',
	'span': {
		position: 'absolute',
		display: 'block',
		width: '114px',
		padding: '5px 0',
		backgroundColor: theme.palette.text.secondary,
		boxShadow: '0 5px 5px rgba(0,0,0,0.2)',
		color: '#fff',
		fontSize: '11px',
		textTransform: 'uppercase',
		textAlign: 'center',
		right: 0,
		top: '15px',
	},
	'&::before' : {
		position: 'absolute',
		zIndex: '-1',
		content: '""',
		display: 'block',
		border: `5px solid ${theme.palette.text.secondary}`,
		borderBottomColor: 'transparent',
		borderRightColor: 'transparent',
		top:'39px',
		right: '0',
	},
}))

