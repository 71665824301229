import React from 'react'
import { Box, Typography } from "@mui/material";

const Footer = () => {
	return (
		<Box sx={{ mt: 5, mb: 5}}>
			<Typography variant="body1" textAlign="center">
				Your info is being provided to
				REIN to create the quote <a target="_blank" href="https://www.rein.ai/privacy-policy">Privacy Policy</a>,
				&nbsp;<a target="_blank" href="https://www.rein.ai/terms-of-use">Do not sell or share my personal
					information</a> REIN may receive a fee if you purchase the represented product
			</Typography>
		</Box>
	)
}

export default Footer
