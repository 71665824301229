/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { StyledButton } from "shared/ui/Button/styles";
import ModalWindow from "shared/ui/ModalWindow/ModalWindow";
import { Controller, useFormContext, useForm } from "react-hook-form";

interface Props {
	open: boolean,
	handleClose: () => void
}

interface Roommate {
	roommate: {
		firstName: string,
		lastName: string,
	}
}

const defaultValues = {
	roommate: {
		firstName: '',
		lastName: '',
	}
};

const RoommatesForm = ({ open, handleClose }: Props) => {
	const { setValue, getValues } = useFormContext();
	const { control, handleSubmit, setValue: setCurrentForm } = useForm({
		defaultValues: { ...defaultValues },
	});

	useEffect(() => {
		const firstName = getValues('additionalInsured.firstName');
		const lastName = getValues('additionalInsured.lastName');

		if (firstName && lastName) {
			setCurrentForm('roommate.firstName', firstName);
			setCurrentForm('roommate.lastName', lastName);
		}
	}, [])


	const onSubmit = () => {
		void handleSubmit((data: Roommate) => {
			setValue('additionalInsured.firstName', data.roommate.firstName);
			setValue('additionalInsured.lastName', data.roommate.lastName);
			handleClose();
		})()
	}

	return (
		<ModalWindow open={open} handleClose={handleClose} header={<Typography variant="h3" textAlign="center" mb={0}>Add a Roommate</Typography>}>
			<Grid container item md={12} justifyContent="center" spacing={2}>
				<Grid item md={12} sm={12} xs={12}>
					<Typography variant="body1" mb={0}>
						Add a roommate so they can protect their personal liability and cover their
						belongings too.
					</Typography>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="roommate.firstName"
						control={control}
						rules={{ required: true }}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="First name"
								error={!!error}
								helperText={error && "* Required"}
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="roommate.lastName"
						control={control}
						rules={{ required: true }}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Last name"
								error={!!error}
								helperText={error && "* Required"}
							/>
						}
					/>
				</Grid>
				<Grid container item spacing={2} justifyContent="center">
					<Grid item md={6} sm={12} xs={12} justifyContent="center" flexDirection="column" display="flex">
						<StyledButton onClick={onSubmit} sx={{ mt: 2 }}>Continue</StyledButton>
						<Button onClick={handleClose}sx={{ color: "text.secondary", textAlign: 'center' }}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</ModalWindow>
	)
}

export default RoommatesForm
