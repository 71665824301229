import React from 'react'

const NotFoundPage = () => {
	return (
		<span>
			Not Found
		</span>
	)
}

export default NotFoundPage
