import React, {useContext, useState} from 'react'
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { AdditionalItemIcon } from 'shared/ui/Accordion';
import { useQuoteQuery } from 'entities/Quote/hooks/queries';
import dayjs from 'dayjs';
import numeral from 'numeral';

const periodsObj: Record<number, string> = {
	1: 'Annual',
	4: '4-pay',
	11: 'Monthly'
}

interface SummaryInfoProps {
	goBack: () => void;
	stepBack: () => void;
}

const SummaryInfo = ({ goBack, stepBack }: SummaryInfoProps) => {
	const { paymentData, formData: { plan }, plan: currentPlan, policyStartDate } = useContext(FormDataContext);

	const quoteQuery = useQuoteQuery()

	const numberWithCommas = (x: string) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
	const phoneMask = (value: string) => {
		const x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
		return x && '(' + x[1] + ') ' + x[2] + '-' + x[3];
	};
	const dateMask = (value: string | Date) => {
		return dayjs(value).format('MM-DD-YYYY');
	};

	const deductible = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'deductible');
	const replacementCost = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'replacementCost');
	const liabilityAmount = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'liabilityAmount');
	const waterBackup = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'waterBackup');
	const earthquake = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'earthquake');
	const hurricaneDeductible = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'hurricaneDeductible');
	const identityFraud = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'identityFraud');
	const personalPropertyAmount = quoteQuery?.coverageDetails.parameters.filter(item => item.name === 'personalPropertyAmount');
	const billingAddress = paymentData?.billingAddress;
	const billingAddressStreet = billingAddress?.address2 ? `${billingAddress?.address1}, ${billingAddress?.address2}` : billingAddress?.address1;
	const mailingAddress = paymentData?.mailingAddress;
	const mailingAddressStreet = mailingAddress?.address2 ? `${mailingAddress?.address1}, ${mailingAddress?.address2}` : mailingAddress?.address1;
	const interestedParty = paymentData?.interestedParty;
	const policyEffectiveDate: string = quoteQuery?.transactionDetails.policyEffectiveDate || '';
	const state = quoteQuery?.policyHolderDetails.insured.address.state;
	const premiumAmount = quoteQuery?.premiumDetails.premiumAmount || 0;
	
	const addMonth = plan.period === 4 ? 2 : 1;
	const [ nextBillingDay ] = useState(dayjs(policyStartDate).add(addMonth, 'month').format('MM/DD/YYYY'));

	const [ disabled, setDisabled ] = useState(false);

	const handleBack = () => {
		setDisabled(true);
		goBack();
	};
	const handleStepBack = () => {
		setDisabled(true);
		stepBack();
	};

	return (
		<Grid container justifyContent="center" spacing={1}>
			<Grid item md={12}>
				<Box border="1px solid grey" borderColor="greyBlue.main" sx={{ p: 2, mt: 4 }}>
					<Box display="flex" alignItems="center" mb={2}>
						<Typography
							variant="h3"
							mb={0}
							fontWeight={800}
							fontSize={28}
							color="text.primary"
						>Renters Insurance Summary</Typography>
					</Box>
					<Box display="flex" alignItems={"baseline"}>
						<Typography
							variant="h3"
							mb={0}
							fontWeight={800}
							fontSize={28}
							color="text.primary"
						>Coverage Info</Typography>
						<Button
							variant="text"
							startIcon={<EditIcon />}
							sx={{marginLeft: '20px', color: '#2780EC'}}
							onClick={handleBack}
							disabled={disabled}
						>Edit</Button>
					</Box>
					<Grid container spacing={2} columnSpacing={12}>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Coverage Start Date</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{dateMask(policyEffectiveDate)}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Renters Payment Option</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>{periodsObj[plan.period]}</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Your belongings</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								${personalPropertyAmount?.[0].value && numberWithCommas(personalPropertyAmount?.[0].value)}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Deductible</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								${deductible?.[0].value && numberWithCommas(deductible?.[0].value)}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Your Liability</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								${liabilityAmount?.[0].value && numberWithCommas(liabilityAmount?.[0].value)}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Replacement Cost</Typography>
							<Typography variant="subtitle2" fontSize={16} sx={{display: 'flex', alignItems: 'center'}} align='right'>
								{replacementCost?.[0].value === 'Y' && <AdditionalItemIcon
									sx={{
										backgroundColor: "orange.main",
										marginLeft: 0,
										marginRight: '15px'
									}}>
									<CheckIcon/>
								</AdditionalItemIcon>}
								{replacementCost?.[0].value === 'Y' ? 'Included' : 'Not Included'}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Water Sewer Backup</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{waterBackup?.[0].value === 'Y' ? 'Added' : 'Not Added'}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Fraud expense protection</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{identityFraud?.[0].value === 'Y' ? 'Added' : 'Not Added'}
							</Typography>
						</Grid>
						{state === 'CA' && <Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Earthquake</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{earthquake?.[0].value === 'Y' ? 'Added' : 'Not Added'}
							</Typography>
						</Grid>}
						{state === 'FL' && <Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Hurricane Deductible</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								${hurricaneDeductible?.[0].value}
							</Typography>
						</Grid>}
					</Grid>
					<Divider sx={{marginTop: '20px'}} />

					<Box display="flex" alignItems={"baseline"}>
						<Typography
							variant="h3"
							mb={0}
							fontWeight={800}
							fontSize={28}
							color="text.primary"
						>Your Information</Typography>
						<Button
							variant="text"
							startIcon={<EditIcon />}
							sx={{marginLeft: '20px', color: '#2780EC'}}
							onClick={handleStepBack}
							disabled={disabled}
						>Edit</Button>
					</Box>
					<Grid container spacing={2} columnSpacing={12}>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Policyholder</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{paymentData?.fullName}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Email</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{paymentData?.email}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Date of Birth</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{paymentData?.dateOfBirth && dateMask(paymentData?.dateOfBirth)}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Phone Number</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{paymentData?.phoneNumber && phoneMask(paymentData.phoneNumber)}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Roommate</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{paymentData?.additionalInsured?.firstName ? 'Added' : 'Not Added'}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Interested Party</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{interestedParty?.name ? <>
									{interestedParty?.name},
									{interestedParty?.streetAddress} {interestedParty?.app} {interestedParty?.city},
									{interestedParty?.state} {interestedParty?.zipcode}
								</> : "Not Added"}
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{marginTop: '20px', marginBottom: '20px', borderStyle: 'dashed'}} />
					<Grid container spacing={2} columnSpacing={12}>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Property Address</Typography>
							<Typography variant="subtitle2" fontSize={16} sx={{maxWidth: '200px'}} align='right'>
								{paymentData?.fullAddress}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Mailing Address</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right' sx={{maxWidth: '200px'}}>
								{mailingAddress?.address1 ? <>
									{mailingAddressStreet}, {mailingAddress?.city},
									{mailingAddress?.state} {mailingAddress?.postalCode}
								</> : 'Same as property address'}
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{marginTop: '20px'}} />
					<Grid container spacing={2} columnSpacing={12} alignItems="baseline">
						<Grid item md={8} sm={12} xs={12}>
							<Typography
								variant="h3"
								mb={0}
								fontWeight={800}
								fontSize={28}
								color="text.primary"
							>Down Payment Amount</Typography>
							{plan.period !== 1 && <Typography variant="subtitle2" fontSize={16} sx={{fontStyle: 'italic'}}>
								{`Your next payment of 
								${numeral(currentPlan.installmentAmount || currentPlan.downPaymentAmount || 0)
			.add(currentPlan.fee ?? 0).format('$0,0[.]00')}
								 will be charged automatically on ${nextBillingDay}.`
								}
							</Typography>}
							
						</Grid>
						<Grid item md={4} sm={12} xs={12} justifyContent="flex-end" display="flex">
							<Typography
								variant="subtitle2"
								fontSize={24}
								color="text.secondary">
								{
									currentPlan.downPaymentAmount ? numeral(currentPlan.downPaymentAmount).format('$0,0[.]00') : `$${premiumAmount}`
								}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>

			<Grid item md={12}>
				<Box border="1px solid grey" borderColor="greyBlue.main" sx={{ p: 2, mt: 4 }}>
					<Box display="flex" alignItems="center" mb={2}>
						<Typography
							variant="h3"
							mb={0}
							fontWeight={800}
							fontSize={28}
							color="text.primary"
						>Payment Information</Typography>
					</Box>

					<Box display="flex" alignItems={"baseline"}>
						<Typography
							variant="h3"
							mb={0}
							fontWeight={800}
							fontSize={28}
							color="text.primary"
						>Payment Info</Typography>
						<Button
							variant="text"
							startIcon={<EditIcon />}
							sx={{marginLeft: '20px', color: '#2780EC'}}
							onClick={handleStepBack}
							disabled={disabled}
						>Edit</Button>
					</Box>
					<Grid container spacing={2} columnSpacing={12}>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Payment Method</Typography>
							<Typography variant="subtitle2" fontSize={16} align='right'>
								{paymentData?.paymentType === 'cc' ? 'Credit/Debit Card' : 'Bank Account'}
							</Typography>
						</Grid>
						<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
							<Typography variant="subtitle2" fontSize={16}>Billing Address</Typography>
							<Typography variant="subtitle2" fontSize={16} sx={{maxWidth: '200px'}} align='right'>
								{paymentData?.billingAddressOption === "useProperty" ? 'Same as property address' : <>
									{billingAddressStreet}, {billingAddress?.city}, {billingAddress?.state} {billingAddress?.zip}
								</>}
							</Typography>
						</Grid>
						{paymentData?.payment.cardNumber ?
							<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
								<Typography variant="subtitle2" fontSize={16}>Card Details</Typography>
								<Typography variant="subtitle2" fontSize={16}>
									**** **** **** {paymentData?.payment.cardNumber.slice(-4)}
								</Typography>
							</Grid> :
							<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
								<Typography variant="subtitle2" fontSize={16}>Account Number</Typography>
								<Typography variant="subtitle2" fontSize={16}>
									**** **** **** {paymentData?.payment.bankAccountNumber.slice(-4)}
								</Typography>
							</Grid>
						}

					</Grid>
				</Box>
			</Grid>
			<Grid item md={12}>
				<Box
					border="1px solid grey"
					borderColor="greyBlue.main"
					sx={{ p: 2, mt: 2 }}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box display="flex" alignItems="center">
						<Typography
							variant="h3"
							mb={0}
							fontWeight={800}
							fontSize={32}
							color="text.secondary"
						> Total Due Today</Typography>
					</Box>
					<Typography
						variant="h3"
						mb={0}
						fontWeight={800}
						fontSize={32}
						color="text.secondary"
					>
						{currentPlan.downPaymentAmount ? numeral(currentPlan.downPaymentAmount).format('$0,0[.]00') : `$${premiumAmount}`}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	)
}

export default SummaryInfo
