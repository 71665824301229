import { styled } from '@mui/material/styles';

export const ErrorChip = styled('div')(() => ({
	margin: '3px 0',
	fontSize: '12px',
	color: '#ef5350',
}))
export const ErrorListWrapper = styled('div')(() => ({
	padding: '10px 20px',
	background: '#fff',
	borderRadius: '8px',
	color: '#ef5350',
	margin: '0',
	fontSize: '12px',
	listStyle: 'none',
}))

export const ErrorList = styled('ul')(() => ({
	padding: '0 10px',
	margin: '0 10px',
}))
