import React, { FC } from "react";

interface Props {
	list?: string[];
}
export const ErrorList: FC<Props> = ({ list }) => {
	return (
		<ul>{list && list.map((item, index) => <li key={index}>{item}</li>)}</ul>
	);
};
