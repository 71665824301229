import { useMutation, useQueryClient } from '@tanstack/react-query';
import Quote, { mockPayload } from 'shared/api/assurant/quote';
import { useContext } from 'react';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { QuoteResponse } from 'shared/api/assurant/quoteResponseTypes';

export const useMutationQuery = () => {
	const queryClient = useQueryClient()
	const { setInitialPayloadData } = useContext(FormDataContext);
	return useMutation({
		mutationFn: Quote.postRentersQuote,
		onSuccess: data => {
			queryClient.setQueryData(['quoteData'], data.data)
		},
		onError: error => {
			console.log('Error getting quote', error);
			setInitialPayloadData(mockPayload)
		}
	})
}
export const useQuoteQuery = () => {
	const client = useQueryClient()
	return client.getQueryData<QuoteResponse>(['quoteData'])
}
