import { FieldError, FieldErrors } from 'react-hook-form';
import { IFormICheckoutInput } from '../config/types/checkout';


type ErrorsTest = keyof (FieldErrors<IFormICheckoutInput> | FieldError)

export const getErrorMessages = (obj: FieldErrors<IFormICheckoutInput> | FieldError): string[] => {
	let messages: string[] = [];
	for (const key in obj) {
		if (typeof obj[key as ErrorsTest] === 'object' && key !== 'ref' && obj[key as ErrorsTest]) {
			messages = messages.concat(getErrorMessages(obj[key as ErrorsTest] as FieldError));
		} else if (key === 'message' && key in obj) {
			obj[key] && messages.push(obj[key] as string);
		}
	}
	return messages;
};
