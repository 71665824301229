import React, { useState, useEffect, useContext } from 'react'
import { Controller, useFormContext } from "react-hook-form";
import { Box, Checkbox, Link, Typography } from "@mui/material";
import { CheckboxItem } from "./styles";
import { useDisclosuresMutationQuery } from 'entities/Disclosures/hooks/queries';
import ElectronicBusinessConsent from './ElectronicBusinessConsent';
import ShortMessageService from './ShortMessageService';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { IFormICheckoutInput } from '../../../shared/config/types/checkout';

const Questions = () => {
	const { schemaAddress } = useContext(FormDataContext);
	const { mutate: getDisclosures } = useDisclosuresMutationQuery();
	const [modalServices, setModalServices] = useState(false);
	const [messageService, setMessageService] = useState(false);
	const { control, watch } = useFormContext<IFormICheckoutInput>();
	useEffect(() => {
		if (modalServices) {
			getDisclosures({
				State: schemaAddress.State,
				Type: 'ebc'
			})
		}
	}, [modalServices, getDisclosures])

	return (
		<Box sx={{ mt: 2, mb: 3 }}>
			<CheckboxItem>
				<Controller
					name="alerts"
					control={control}
					render={({ field }) =>{
						return  <Checkbox {...field} checked={field.value} disableRipple/>
					}}
				/>
				<div>
					<Typography variant="body2">I'd like to receive text alerts on my phone.</Typography>
					<Typography variant="body1">We'll send you texts keeping you up to date on your policy, never sales
						pitches.</Typography>
				</div>
			</CheckboxItem>
			<CheckboxItem>
				<Controller
					name="policy"
					control={control}
					render={({ field }) => <Checkbox {...field} checked={field.value} disableRipple/>}
				/>
				<Typography variant="body2">I'd like to receive all policy and billing documents by email.</Typography>
			</CheckboxItem>
			<Typography variant="subtitle1">
				By clicking "Make Payment" below, I acknowledge that I have read, agreed and understood these
				disclosures:
				<Link
					color="text.secondary"
					underline="hover"
					sx={{ cursor: 'pointer' }}
					onClick={() => setModalServices(true)}> Electronic Business Consent</Link> and
				<Link
					color="text.secondary"
					underline="hover"
					sx={{ cursor: 'pointer' }}
					onClick={() => setMessageService(true)}> Short Message Service</Link>.
			</Typography>
			{modalServices &&
				<ElectronicBusinessConsent
					open={modalServices}
					handleClose={() => setModalServices(false)}
				/>}

			{messageService &&
				<ShortMessageService
					open={messageService}
					handleClose={() => setMessageService(false)}
				/>}
		</Box>
	)
}

export default Questions
