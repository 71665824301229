import {styled} from "@mui/material/styles";
import ToggleButtonGroup, {
	toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';

export const CoverageItem = styled("div")(() => ({
	padding: 0,
	minWidth: 205,
	height: '100%',
	minHeight: '175px',
	borderRadius:'4px',
}))

export const TotalBlock = styled("div")(({theme}) => ({
	backgroundColor:  theme.palette.green.main,
	padding: '24px 12px',
	color: theme.palette.secondary.main,
	borderRadius: '0 0 8px 8px',
}))

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	[`& .${toggleButtonGroupClasses.grouped}`]: {
		'& h6': {
			textTransform: 'capitalize',
			color: theme.palette.secondary.main,
		},
		'&:hover': {
			'& h6': {
				color: theme.palette.green.main,
			},
			backgroundColor: theme.palette.secondary.main,
		},
		'&.Mui-selected': {
			'& h6': {
				color: theme.palette.green.main,
			},
			backgroundColor: theme.palette.secondary.main,
			border: `1px solid ${theme.palette.secondary.main}`,
		},
		padding: '7px 10px',
		borderColor: theme.palette.secondary.main,
	},
}));
