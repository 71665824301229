/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useContext, useEffect, useRef } from 'react'
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import RentersForm from "widgets/RentersForm/RentersForm";
import { Navigate } from 'react-router-dom';
import Loader from 'shared/ui/Loader/Loader';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { useMutationQuery, useQuoteQuery } from 'entities/Quote/hooks/queries';
import { useApplicationFormQuery } from 'entities/ApplicationForm/hooks/queries';
import { getDwellingType } from 'shared/utils/helpers';
import { Sidebar } from 'features/CoverageSidebar';
import { FormProvider, useForm } from 'react-hook-form';
import { IFormInput } from '../../app/providers/formValues/ui/FormDataProvider';
import { Plans } from '../../shared/config/enums/formEnums';

const QuotePage = () => {
	const { initialPayload, schemaAddress, policyStartDate, setProtections } = useContext(FormDataContext);
	const matchesSM = useMediaQuery('(max-width:900px)');
	const refContainer = useRef<HTMLDivElement | null>(null);
	const { mutate: createNext, isPending, isSuccess, isError } = useMutationQuery();
	const query = useApplicationFormQuery();
	const queryQuote = useQuoteQuery();

	const methods = useForm<IFormInput>({
		defaultValues: {
			plan: {
				period: Plans.ANNUAL,
				price: '$31.59'
			},
			liability: queryQuote?.coverageDetails.parameters.find((item) => item.name === 'liabilityAmount')?.value,
			belongings: 5000,
			deductible: queryQuote?.coverageDetails.parameters.find((item) => item.name === 'deductible')?.value || '250'
		}
	})

	const waterBackup = queryQuote?.coverageDetails.parameters.filter((item) => item.name === "waterBackup")[0];
	const identityFraud = queryQuote?.coverageDetails.parameters.filter((item) => item.name === "identityFraud")[0];
	const earthquake = queryQuote?.coverageDetails.parameters.filter((item) => item.name === "earthquake")[0];
	const replacementCost = queryQuote?.coverageDetails.parameters.filter((item) => item.name === "replacementCost")[0];

	useEffect(() => {
		setProtections(prevState => {
			return {
				...prevState,
				...(waterBackup && { ["waterBackup"]: waterBackup.value || 'N' }),
				...(identityFraud && { ["identityFraud"]: identityFraud.value || 'N' }),
				...(earthquake && { ["earthquake"]: earthquake.value || 'N' }),
				...(replacementCost && { ["replacementCost"]: replacementCost.value || 'N' }),
			}
		});
	}, [waterBackup, identityFraud, earthquake, replacementCost]);

	useEffect(() => {
		if (schemaAddress.Address1 && query?.dynamicSchema?.definitions) {
			const underwritingKeys = Object.keys(query.dynamicSchema.definitions).filter((key) => key.startsWith('Underwriting'));
			const underwritingData = query.dynamicSchema.definitions[underwritingKeys[0]]?.default;

			const dwellingType = underwritingData?.PropertyUnderwriting?.PropertyType && getDwellingType(underwritingData?.PropertyUnderwriting?.PropertyType);

			createNext({
				...initialPayload,
				transaction: {
					...initialPayload.transaction,
					"referralId": "Ref12345",
					policyEffectiveDate: policyStartDate
				},
				policyHolder: {
					...initialPayload.policyHolder,
					insured: {
						address: {
							...initialPayload.policyHolder.insured.address,
							address1: schemaAddress.Address1,
							address2: schemaAddress.Address2,
							city: schemaAddress.City,
							state: schemaAddress.State,
							postalCode: schemaAddress.ZipCode,
						}
					},
				},
				underWriting: {
					questions: [
						...initialPayload.underWriting.questions,
						{
							"questionName": "dwellingType",
							"answer": dwellingType
						},
						{
							"questionName": "howManyLosses",
							"answer": underwritingData?.PropertyUnderwriting?.PriorLossExcludingNaturalDisaster ||
								underwritingData?.PropertyUnderwriting?.PriorLossExcludingNaturalDisasterMI ||
								underwritingData?.PropertyUnderwriting?.PriorLossExcludingNaturalDisasterFL ||
								underwritingData?.PropertyUnderwriting?.HaveYouHadAnyLossesFromTheftOrBurglaryOrVandalismOrFireOrCausedDamageToAPropertyInThePastThreeYears || ""
						},
						{
							"questionName": "lastLossDate",
							"answer": underwritingData?.PriorLossUnderwriting.DateOfLoss || ""
						},
						{
							"questionName": "isManufacturedHome",
							"answer": underwritingData?.PropertyUnderwriting.ManufacturedOrMobileHome === "Yes" ? "Y" : "N"
						},
					]
				}
			})
		}
	}, [createNext, schemaAddress, query])

	if (isError && !isPending) {
		return <Navigate to="/error"/>;
	}
	if (isPending) {
		return <Loader/>
	}
	return (
		<>
			{isSuccess && (
				<Grid container>
					<FormProvider {...methods}>
						<Grid item xs={12} md={8} lg={8} sm={12}>
							<Container maxWidth={"md"} sx={{ mt: 5 }}>
								<Typography variant={"h3"} align={"center"} sx={{ mb: 0 }}>Your Renters Insurance</Typography>
								<Typography variant={"body1"} align={"center"} color="text.primary">
									You and your belongings are protected from a variety of incidents, even if you’re
									travelling.
								</Typography>
								<Box sx={{ mb: 2 }}>
									{isSuccess && <RentersForm/>}
								</Box>
							</Container>
						</Grid>
						<Grid item xs={12} md={4} lg={4} sm={12}>
							<Container maxWidth={"md"} sx={{ mt: 5, width: 'inherit' }} ref={refContainer}>
								{!matchesSM && <Sidebar refContainer={refContainer}/>}
							</Container>
						</Grid>
					</FormProvider>
				</Grid>)
			}
		</>
	)
}

export default QuotePage
