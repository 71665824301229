import {styled} from "@mui/material/styles";
import {FormControlLabel} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const StyledModeEditIcon = styled(ModeEditIcon)(() => ({
	position: 'absolute',
	right: '5px',
	top: '13px',
}))

export const ControlLabel = styled(FormControlLabel, {
	shouldForwardProp: (prop) =>
		prop !== 'checked'
})<{ checked: boolean }>(({checked, theme}) => ({
	width: '100%',
	padding: '8px',
	borderRadius: '4px',
	margin: '0 1px',
	position: 'relative',
	background: '#fff',
	color: theme.palette.greyBlue.main,
	boxSizing: 'border-box',
	userSelect: 'none',
	cursor: 'pointer',
	'& .MuiTypography-root': {
		width: '100%',
		color: theme.palette.darkGrey.primary,
	},
	'&::after': {
		content: "''",
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: -1,
		margin: '-1px',
		borderRadius: '5px',
		backgroundImage: checked ? theme.palette.gradient.main : 'linear-gradient(to right bottom, #CDD8DE, #CDD8DE)',
	}
}))

export const RadioWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
}))

export const LabelWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'space-between'
}))

export const Popular = styled('div')(({theme}) => ({
	marginRight: '15px',
	backgroundColor: theme.palette.text.secondary,
	borderRadius: '50px',
	padding: '0px 8px',
	color: "#FFFFFF",
	fontFamily: 'Noto Sans',
	fontSize: '10px'
}))
