import React from 'react'
import { LoaderElement, LoaderWrapper, Spinner } from './styles';

interface Props {
	color?: string
}
const Loader = ({color}: Props) => {
	return (
		<LoaderWrapper color={color}>
			<LoaderElement>
				<Spinner />
			</LoaderElement>
		</LoaderWrapper>
	)
}

export default Loader
