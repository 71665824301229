import React, { ReactNode, useMemo, useState } from 'react'
import { ApplicationFormContext } from "../lib/ApplicationFormContext";
import { DynamicSchemaResponse } from 'shared/api/types';
import { ApplicationAddresses } from 'shared/api/assurant/quotePayloadTypes';

interface Props {
	children?: ReactNode;
}

const ApplicationFormProvider = ({ children }: Props) => {
	const [applicationFormData, setApplicationFormData] = useState<DynamicSchemaResponse>({
		dynamicSchema: {
			definitions: {},
			type: '',
			additionalProperties: '',
			properties: {},
		},
	});
	const [applicationAddress, setApplicationAddress] = useState<ApplicationAddresses>(
		{
			mailingAddress: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: '',
			},
			billing: {
				address: {
					address1: '',
					address2: '',
					city: '',
					state: '',
					postalCode: '',
				},
				firstName: '',
				lastName: '',
			},
			propertyAddress: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: '',
			}
		}
	);
	const defaultProps = useMemo(() => ({
		applicationAddress,
		applicationFormData,
		setApplicationAddress,
		setApplicationFormData,
	}), [applicationAddress, applicationFormData, setApplicationAddress, setApplicationFormData]);

	return (
		<ApplicationFormContext.Provider value={defaultProps}>
			{children}
		</ApplicationFormContext.Provider>
	)
}

export default ApplicationFormProvider;
