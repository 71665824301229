/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useContext, useMemo, useRef, useState } from 'react'
import { Grid, TextField, Typography, Button, Box, Autocomplete } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { useDebounce } from 'shared/utils/useDebounce';
import { locationAutocomplete, smartyStreetsAutocomplete } from 'shared/api';
import { AddressDataProps, AddressProps } from './types';

import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { additionalBillingFields } from './Billing';
import { ErrorChip } from '../../CheckoutErrorsInfo/styles';
import { DropDownMenu, DropDownMenuElement } from './styles';
import { StatesArray } from 'shared/utils/statesHash';
import { AutocompleteField } from '../../../shared/ui/Fields';

interface Props {
	saveAddress: (data: BillingAddress) => void,
	billingAddress: string
	billingOptionControl:  "useProperty" | "useDifferentBilling"
}

export interface BillingAddress {
	firstName: string,
	lastName: string,
	address1: string,
	address2: string,
	city: string,
	state: string,
	zip: string,
}

const AdditionalBilling = ({ saveAddress, billingAddress, billingOptionControl }: Props) => {
	const { setValue, control, getValues, formState, trigger } = useFormContext<IFormICheckoutInput>()

	const [addressData, setAddressData] = useState<AddressDataProps[] | null>(null);
	const [isElementFocused, setIsElementFocused] = useState(false);
	const [inputValue, setInputValue] = React.useState('');

	const dropdownBlock: any = useRef(null);
	const inputRef: any = useRef(null);

	const {errors} = formState
	const { applicationAddress } = useContext(ApplicationFormContext);
	const billingAddressWatcher = useWatch({control, name: 'billingAddress'});
	const fieldsData = useMemo(() => {
		const billingData: string[] = Object.keys(billingAddressWatcher)
			.filter((item) => item !== 'address2' && billingAddressWatcher[item as keyof BillingAddress] === '' )
		return billingData.length === 0
	}, [billingAddressWatcher]);
	const debouncedValue = useDebounce(billingAddressWatcher.address1, 500);

	useEffect(() => {
		const getAutocompleteResult = async () => {
			try {
				const response = await locationAutocomplete(debouncedValue);
				if (response.data.items.length > 0) {
					setAddressData(response.data.items);
				} else {
					const responseSuggestions = await smartyStreetsAutocomplete(debouncedValue);
					const formattedData: AddressDataProps[] = responseSuggestions?.data?.suggestions?.map((item: any) => ({
						address: {
							city: item?.city,
							countryCode: '',
							countryName: '',
							county: '',
							houseNumber: '',
							label: item?.street_line,
							postalCode: item?.zipcode,
							state: '',
							stateCode: item?.state,
							street: item?.street_line
						}
					}));
					setAddressData(formattedData);
				}

			} catch (e) {
				setAddressData(null);
			}
		};
		if (debouncedValue) getAutocompleteResult().then(r => r);
	}, [debouncedValue]);

	useEffect(() => {
		void trigger('billingSave')
	}, [billingAddress])

	useEffect(() => {
		const { billing } = applicationAddress;
		const address = billing.address;
		if (address) {
			setValue('billingAddress.address1', address.address1);
			if (address.address2) setValue('billingAddress.address2', address.address2);
			setValue('billingAddress.city', address.city);
			setValue('billingAddress.state', address.state);
			setValue('billingAddress.zip', address.postalCode);
			setValue('billingAddress.firstName', billing.firstName);
			setValue('billingAddress.lastName', billing.lastName);
		}
	}, [])

	const onSubmit = () => {
		const submit = async () => {
			await trigger(				additionalBillingFields)
			const {errors} = formState
			const {billingAddress} = getValues()
			if(!errors.billingAddress) {
				saveAddress(billingAddress)
			}
		}
		void submit()
	}
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.name as keyof IFormICheckoutInput, e.target.value, {shouldValidate: !billingAddress});
	};

	const handleAddress = (data: AddressProps) => () => {
		const address1 = data.houseNumber ? `${data.houseNumber} ${data.street}` : data.street;
		setValue("billingAddress.address1", address1, {shouldValidate: !billingAddress});
		setValue("billingAddress.city", data.city, {shouldValidate: !billingAddress});
		setValue("billingAddress.state", data.stateCode, {shouldValidate: !billingAddress});
		setValue("billingAddress.zip", data.postalCode.split('-').shift() ?? '', {shouldValidate: !billingAddress});

		setIsElementFocused(false);
	};

	const handleFocusChange = (event: MouseEvent) => {
		if (dropdownBlock.current && isElementFocused && !dropdownBlock.current?.contains(event.target)) {
			setIsElementFocused(false);
		}
		if (inputRef.current && !isElementFocused && inputRef.current?.contains(event.target)) {
			setIsElementFocused(true);
		}
	};
	useEffect(() => {
		addEventListener('mousedown', handleFocusChange, false);
		return () => {
			removeEventListener('mousedown', handleFocusChange, false);
		};
	}, [isElementFocused]);
	return (
		<>
			<Grid item md={6} sm={6} xs={12}>
				<Controller
					name="billingAddress.firstName"
					control={control}
					rules={{
						required: { value:true, message: 'First name is required' },
						maxLength: { value: 30, message: "First name has to be max 30 chars." }
					}}
					render={({field, fieldState: { error }}) =>
						<TextField
							{...field}
							fullWidth
							variant="outlined"
							label="First name"
							onChange={onInputChange}
							error={!!error}
							helperText={error && error.message}
						/>
					}
				/>
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Controller
					name="billingAddress.lastName"
					control={control}
					rules={{
						required: { value:true, message: 'Last name is required' },
						maxLength: { value: 30, message: "Last name has to be max 30 chars." }
					}}
					render={({field, fieldState: { error }}) =>
						<TextField
							{...field}
							fullWidth
							variant="outlined"
							label="Last name"
							onChange={onInputChange}
							error={!!error}
							helperText={error && error.message}
						/>
					}
				/>
			</Grid>
			<Grid item md={12} sm={12} xs={12} ref={dropdownBlock} sx={{ position: 'relative' }}>
				<Controller
					name="billingAddress.address1"
					control={control}
					rules={{
						required: { value:true, message: 'Billing Street address is required' },
						maxLength: { value: 30, message: "Billing Street has to be max 30 chars." }
					}}
					render={({field, fieldState: { error }}) =>
						<TextField
							{...field}
							error={!!error}
							fullWidth
							variant="outlined"
							label="Street address"
							onChange={onInputChange}
							helperText={error && error.message}
							ref={inputRef}
						/>
					}
				/>
				{isElementFocused && addressData && addressData?.length > 0 && (
					<DropDownMenu>{addressData?.map((item: any) => {
						return	<DropDownMenuElement
							key={item.address?.label}
							onClick={handleAddress(item.address)}>
							{item.address?.label}
						</DropDownMenuElement>;
					})}</DropDownMenu>
				)}
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Controller
					name="billingAddress.address2"
					control={control}
					rules={{
						maxLength: { value: 30, message: "Apt/Suite has to be max 30 chars." }
					}}
					render={({field, fieldState: { error }}) =>
						<TextField
							{...field}
							fullWidth
							variant="outlined"
							label="Apt/Suite"
							error={!!error}
							helperText={error && error.message}
						/>}
				/>
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Controller
					name="billingAddress.city"
					control={control}
					rules={{
						required: { value:true, message: 'Billing City is required' },
						maxLength: { value: 27, message: "Billing City has to be max 27 chars." }
					}}
					render={({field, fieldState: { error }}) =>
						<TextField
							{...field}
							disabled
							fullWidth
							variant="outlined"
							label="City"
							onChange={onInputChange}
							error={!!error}
							helperText={error && error.message}
						/>
					}
				/>
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Controller
					name="billingAddress.state"
					control={control}
					rules={{
						required: { value:true, message: 'Billing State is required' },
						maxLength: { value: 30, message: "Billing State has to be max 30 chars." }
					}}
					render={({ field, fieldState: { error } }) => {
						const {value} = field
						const dataValue = StatesArray.find((item) => item.id === value)
						return (
							<AutocompleteField
								disabled
								value={dataValue}
								inputValue={dataValue ? `${dataValue.label} - ${dataValue.id}` : inputValue}
								onChange={(event: React.SyntheticEvent, newValue) => {
									setValue('billingAddress.state', newValue?.id ?? '');
									trigger('billingAddress.state')
								}}
								onInputChange={(event, newInputValue) => {
									setInputValue(newInputValue);
								}}
								getOptionLabel={option => `${option.label} - ${option.id}`}
								error={error}
							/>
						)
					}
					}
				/>
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Controller
					name="billingAddress.zip"
					control={control}
					rules={{
						required: { value:true, message: 'Billing Zip Code is required' },
						pattern: { value: /^\d{5}(?:[-\s]\d{4})?$/i, message: 'Invalid zip code' },
						maxLength: { value: 30, message: "Billing Zip Code has to be max 30 chars." }
					}}
					render={({field, fieldState: { error }}) =>
						<TextField
							{...field}
							fullWidth
							disabled
							variant="outlined"
							label="Zip code"
							onChange={onInputChange}
							error={!!error}
							helperText={error && error.message}
						/>
					}
				/>
				{
					billingOptionControl === 'useDifferentBilling' && (
						<Box sx={{ display: 'none' }} >
							<Controller
								name="billingSave"
								control={control}
								rules={{
									required: {
										value: fieldsData && !billingAddress && !formState.errors.billingAddress,
										message: 'You must click the “Save Address” button'
									},
								}}
								render={({ field }) =>
									<TextField {...field} fullWidth variant="outlined" label="Apt/Suite" onChange={() => undefined}/>}
							/>
						</Box>
					)
				}
			</Grid>
			<Grid container item md={12} textAlign="right">
				<Grid item md={6} textAlign="left">
					{errors.billingSave && <ErrorChip>{errors.billingSave.message}</ErrorChip>}
				</Grid>
				<Grid item md={6} textAlign="right">
					<Button onClick={onSubmit}>
						<Typography
							variant="body1"
							color={"text.secondary"}
							sx={{ mr: 0, cursor: 'pointer' }}>
							Save Address
						</Typography>
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default AdditionalBilling
