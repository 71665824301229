/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import { Typography } from "@mui/material";
import ModalWindow from "shared/ui/ModalWindow/ModalWindow";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';

interface ElectronicBusinessConsentProps {
	open: boolean;
	handleClose: () => void;
}

const ElectronicBusinessConsent = ({handleClose, open}: ElectronicBusinessConsentProps) => {
	const { applicationFormData } = useContext(ApplicationFormContext);
	const ebcKeys = Object.keys(applicationFormData.dynamicSchema.definitions).filter((key) => key.includes('Ebc'));
	const ebcData: any = applicationFormData?.dynamicSchema?.definitions[ebcKeys[0]];
	const createMarkup = () => {
		return {__html: ebcData.default.Value};
	}
	return (
		<ModalWindow
			open={open}
			handleClose={handleClose}
			header={<Typography variant="h4" mb={0}>
				Electronic Business Consent
			</Typography>}
		>
			<Typography gutterBottom variant="subtitle1">
				{ebcData?.default?.Value && <div style={{'maxHeight': '90vh'}} dangerouslySetInnerHTML={createMarkup()} />}
			</Typography>
		</ModalWindow>
	)
}

export default ElectronicBusinessConsent
