/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApplicationForm from 'shared/api';
import { ApplicationProcessResponse } from 'shared/api/types';

export const useProcessMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: ApplicationForm.getApplicationProcess,
		onSuccess: data => {
			const applicationprocess = data.data;
			queryClient.setQueryData(['applicationprocess'], applicationprocess);
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useApplicationProcessQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<ApplicationProcessResponse>(['applicationprocess']);
}
