import { $disclosureApi } from '../../index';
import { DisclosureResponse, DisclosurePayload } from './types';

export default class Disclosures {
	static async getData(data: DisclosurePayload): Promise<DisclosureResponse> {
		return $disclosureApi.get(``, {
			headers: {
				'Type': data.Type,
				'State': data.State
			}
		});
	}
}
