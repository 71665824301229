/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState, useContext } from 'react'
import { Wrapper } from 'shared/ui/Wrapper/Wrapper'
import { Box, Fade, Grid, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import AdditionalBilling, { BillingAddress } from "./AdditionalBilling";
import RadioButton from "shared/ui/RadioButton/RadioButton";
import { fullAddress } from 'widgets/CheckoutForm/helper';
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { KeyProperty } from 'shared/config/types/generic';
import { FormDataContext } from '../../../app/providers/formValues/lib/FormDataContext';


export const additionalBillingFields: KeyProperty<BillingAddress, 'billingAddress'>[]  = [
	'billingAddress.address1',
	'billingAddress.address2',
	'billingAddress.city',
	'billingAddress.state',
	'billingAddress.zip',
	'billingAddress.firstName',
	'billingAddress.lastName'
]

const Billing = () => {
	const { paymentData } = useContext(FormDataContext);
	const { control, watch, reset, resetField, getValues } = useFormContext<IFormICheckoutInput>()
	const billingOptionControl = watch('billingAddressOption')
	const [formReset, setFormReset] = useState(false);
	const [showOptionalAddress, setShowOptionalAddress] = useState(false);
	const propertyAddress = getValues("fullAddress");
	const [billingAddress, setBillingAddress] = useState("");
	const { applicationAddress, setApplicationAddress } = useContext(ApplicationFormContext);

	useEffect(() => {
		if(paymentData && paymentData.billingAddressOption === 'useDifferentBilling') {
			processBillingAddress(paymentData.billingAddress)
		}
	}, [paymentData]);
	const onBillingSubmit = (data: BillingAddress) => {
		processBillingAddress(data);
		setShowOptionalAddress(!showOptionalAddress);
	}
	const processBillingAddress = (data: BillingAddress) => {
		const address1 = data.address1;
		const city = data.city;
		const state = data.state;
		const postalCode = data.zip;
		const address2 = data.address2;

		setApplicationAddress({
			...applicationAddress,
			billing: {
				address: {
					city: data.city,
					state: data.state,
					postalCode: data.zip,
					address1: data.address1,
					address2: data.address2,
				},
				firstName: data.firstName,
				lastName: data.lastName,
			}
		});

		const optionalAddress = fullAddress({address1, address2, city, state, postalCode});
		setBillingAddress(optionalAddress);
	};

	useEffect(() => {
		if (billingOptionControl === 'useProperty' && !formReset) {
			for(const item of additionalBillingFields) {
				resetField(item)
			}
			if (showOptionalAddress) setShowOptionalAddress(false);
			setBillingAddress("");
			setFormReset(true);
		}
	}, [billingOptionControl, formReset, getValues, setBillingAddress, showOptionalAddress])

	useEffect(() => {
		if (billingOptionControl === 'useDifferentBilling' && formReset) {
			setFormReset(false);
		}
	}, [billingOptionControl, reset, formReset])
	return (
		<Box sx={{mb: 3}}>
			<Wrapper>
				<Typography variant="h4">Billing address</Typography>
				<Grid container spacing={3.1}>
					<Grid item md={12} sm={12} xs={12}>
						<Controller
							name="billingAddressOption"
							control={control}
							render={({field}) => {
								return (
									<RadioGroup
										{...field}
										aria-label="icon-radio"
									>
										<Grid container spacing={2}>
											<Grid item md={6} sm={6} xs={12}>
												<RadioButton
													checked={field.value === 'useProperty'}
													value={'useProperty'}
													label="Use property address:"
													label2={propertyAddress}
												/>
											</Grid>
											<Grid item md={6} sm={6} xs={12}>
												<RadioButton
													checked={field.value === 'useDifferentBilling'}
													value={'useDifferentBilling'}
													label="Use a different billing address:"
													label2={billingAddress}
													showEdit={billingAddress !== ''}
													handleEdit={() => setShowOptionalAddress(!showOptionalAddress)}
												/>
											</Grid>
										</Grid>
									</RadioGroup>
								)
							}}/>
					</Grid>
				</Grid>
				<Fade in={billingOptionControl === 'useDifferentBilling'} unmountOnExit>
					<Grid container spacing={2} sx={{mt: 0.5}}>
						{!showOptionalAddress && (
							<AdditionalBilling
								saveAddress={onBillingSubmit}
								billingAddress={billingAddress}
								billingOptionControl={billingOptionControl}
							/>
						)}
					</Grid>
				</Fade>
			</Wrapper>
		</Box>
	)
}

export default Billing
