/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { StyledButton } from "shared/ui/Button/styles";
import ModalWindow from "shared/ui/ModalWindow/ModalWindow";
import { Controller, useFormContext, useForm } from "react-hook-form";

interface Props {
	open: boolean,
	handleClose: () => void
}

const defaultValues = {
	firstName: '',
	lastName: '',
};

interface AuthorziedUser {
	firstName: string,
	lastName: string,
}

const AuthorizedUserForm = ({ open, handleClose }: Props) => {
	const { setValue, getValues } = useFormContext();

	const { control, handleSubmit, setValue: setCurrentForm } = useForm({
		defaultValues: { ...defaultValues },
	});

	useEffect(() => {
		const firstName = getValues('authorizedUser.firstName');
		const lastName = getValues('authorizedUser.lastName');

		if (firstName && lastName) {
			setCurrentForm('firstName', firstName);
			setCurrentForm('lastName', lastName);
		}
	}, [])

	const onSubmit = () => {
		void handleSubmit((data: AuthorziedUser) => {
			setValue('authorizedUser.firstName', data.firstName);
			setValue('authorizedUser.lastName', data.lastName);
			handleClose();
		})()
	}

	return (
		<ModalWindow open={open} handleClose={handleClose} header={<Typography variant={"h4"} mb={0}>Add an Authorized User</Typography>}>
			<Grid container item md={12} justifyContent="center" spacing={2}>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="firstName"
						control={control}
						render={({ field }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="First name"
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="lastName"
						control={control}
						render={({ field }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Last name"
							/>
						}
					/>
				</Grid>
				<Grid container item spacing={2} justifyContent="center">
					<Grid item md={6} sm={12} xs={12} justifyContent="center" flexDirection="column" display="flex">
						<StyledButton onClick={onSubmit} sx={{ mt: 2 }}>ADD AUTHORIZED USER</StyledButton>
						<Button onClick={handleClose} sx={{ color: "text.secondary", textAlign: 'center' }}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</ModalWindow>
	)
}

export default AuthorizedUserForm
