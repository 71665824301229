/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Steps from "widgets/Steps/Steps";
import { ReactComponent as Logo } from 'assets/images/rein_logo.svg'
import { useFormBackMutationQuery } from 'entities/ApplicationFormBack/hooks/queries';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { usePolicyQuery, usePolicyMutationQuery } from 'entities/Policy/hooks/queries';
import { StyledButton } from "shared/ui/Button/styles";
import DisclosuresBanner from "widgets/DisclosuresBanner";
import RecurringPaymentAuthorization from "widgets/RecurringPaymentAuthorization";
import { useFormMutationQuery, useApplicationFormQuery } from 'entities/ApplicationForm/hooks/queries';
import { useProcessMutationQuery, useApplicationProcessQuery } from 'entities/ApplicationProcess/hooks/queries';
import { policyPayload, attributesData } from 'widgets/CheckoutForm/helper';
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { useApplicationDataMutationQuery } from 'entities/ApplicationData/hooks/queries';
import SummaryInfo from 'widgets/SummaryInfo';
import { CoverageDetailsParameter } from '../../shared/api/assurant/quoteResponseTypes';
import { useQuoteQuery } from '../../entities/Quote/hooks/queries';

const SummaryPage = () => {
	const navigate = useNavigate();
	const { formId, paymentData, query, formData } = useContext(FormDataContext);
	const { applicationAddress } = useContext(ApplicationFormContext);

	const { plan } = formData;

	const [disclosuresBannerOpen, setDisclosuresBannerOpen] = useState(false);
	const [paymentBannerOpen, setPaymentBannerOpen] = useState(false);

	const { mutate: backButton, isSuccess } = useFormBackMutationQuery();
	const { mutate: fetchForm } = useFormMutationQuery();
	const { mutate: getApplicationProcess, isSuccess: processSuccess } = useProcessMutationQuery();
	const { mutate: makePayment, isSuccess: paymentSuccess, isPending,  data} = usePolicyMutationQuery();
	const { mutate: updateData } = useApplicationDataMutationQuery();
	const policyQuery = usePolicyQuery();
	const applicationProcessQuery = useApplicationProcessQuery();
	const applicationFormQuery = useApplicationFormQuery();

	const isSmall = useMediaQuery('(max-width:600px)');
	const applicationFormId = localStorage.getItem("applicationFormId");

	useEffect(() => {
		if(!paymentData) {
			navigate(-1);
		}
	}, [paymentData])

	const methods = useForm<IFormICheckoutInput>({
		defaultValues: {
			firstName: '',
			lastName: '',
			fullAddress: '',
			fullName: '',
			dateOfBirth: '',
			email: '',
			phoneNumber: '',
			alerts: false,
			policy: false,
			communityName: '',
			propertyRadio: 'sameAddress',
			billingAddressOption: 'useProperty',
			billingAddress: {
				address1: '',
				city: '',
				state: '',
				address2: '',
				lastName: '',
				zip: '',
				firstName: ''
			},
			paymentType: 'cc',
			payment: {
				cardName: '',
				cardCode: '',
				cardDate: '',
				cardNumber: '',
				bankAccountNumber: '',
				bankAccountNumConfirm: '',
				bankRoutingNumber: '',
			},
			mailingAddress: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: ''
			},
			mailingSave: '',
			billingSave: ''
		}
	});
	const { setError } = methods;

	useEffect(() => {
		if (isSuccess && applicationFormId) {
			getApplicationProcess(applicationFormId);
		}
	}, [isSuccess]);

	useEffect(() => {
		if(processSuccess && applicationFormId) {
			if (
				applicationProcessQuery?.applicationProcessStatus === "WaitingForUserInput"
			) {
				fetchForm(applicationFormId);
				navigate(-2);
			} else {
				setTimeout(() => {
					getApplicationProcess(applicationFormId);
				}, 400);
			}
		}
	}, [processSuccess]);

	const goBack = () => {
		if (formId) backButton(formId);
	}
	const stepBack = () => {
		navigate(-1);
	}
	const onSubmit = () => {
		if (!query) return;
		policyPayload(query, paymentData, plan, applicationAddress)
		makePayment(policyPayload(query, paymentData, plan, applicationAddress));
	}
	useEffect(() => {
		if (paymentSuccess && applicationFormId && applicationFormQuery?.dynamicSchema && data) {

			const quoteKeys = Object.keys(applicationFormQuery.dynamicSchema.definitions).filter((key) => key.includes('QuoteInfo'));
			const quoteInfo = applicationFormQuery?.dynamicSchema.definitions[quoteKeys[0]];
			const formValue: IFormICheckoutInput = methods.getValues();
			const newDataParams = data.data.coverageDetails.parameters.reduce<Record<string, string | null>>((accum, item) => {
				accum[item.name] = item.value;
				return accum;
			}, {} as Record<string, string | null>)
			const applicationFormData = attributesData(
				formValue,
				quoteInfo,
				policyQuery,
				data.data,
				newDataParams,
				query,
				paymentData
			);
			// this is for tracking error from Assurant for their 200 OK response.
			// if policy is issued, this response will be null.
			const assurantError = policyQuery?.transactionDetails?.responseMessage;
			const policyId = policyQuery?.transactionDetails?.policyId;
			if (assurantError && !policyId) {
				setError('root.serverError', {
					type: 'server error',
					message: assurantError,
				})
				return;
			}
			updateData({
				applicationFormId,
				data: {
					attributesData: applicationFormData
				},
			});
			navigate('/confirmation');
		}
	}, [paymentSuccess, applicationFormQuery, data]);

	return (
		<Container maxWidth={"xl"}>
			<Grid
				container
				spacing={0}
				sx={{ minHeight: '100vh' }}
			>
				<Grid item xs={12}>
					<Box sx={{ textAlign: 'center', position: 'relative' }} mt={5} mb={5}>
						<Logo width={171} height={56}/>
					</Box>
					<Steps />
					<Container maxWidth={"lg"} sx={{ mt: 5 }}>
						<Box display="flex" alignItems="center" mb={2} justifyContent="center">
							<Typography variant={"h3"} align={"center"} fontSize={42} sx={{ mb: 0 }}>One Last Review</Typography>
						</Box>
						<SummaryInfo goBack={goBack} stepBack={stepBack} />

						<Grid container mt={4}>
							<Grid item xs={12} md={8} lg={8}>
								<Grid container justifyContent={isSmall ? "center" : "end"}>
									<Grid item xs={6} md={6} lg={5}>
										<StyledButton
											loading={isPending}
											loadingPosition="start"
											variant="outlined"
											onClick={onSubmit}
										>
											PURCHASE NOW
										</StyledButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Box sx={{ mt: 5, mb: 5}}>
							<Typography variant="body1" sx={{
								fontSize: '14px',
								lineHeight: '20px',
								'& a': {
									color: '#2780EC',
									cursor: 'pointer',
									textDecoration: 'none'
								}
							}}>
								By clicking "Purchase Now", I acknowledge that I have read and understand all applicable
								<a onClick={() => setDisclosuresBannerOpen(true)}> state disclosures, fraud notices & notice of cancellation</a>,
								and <a onClick={() => setPaymentBannerOpen(true)}>recurring payment authorization</a>.
							</Typography>
						</Box>
					</Container>
				</Grid>
			</Grid>
			<DisclosuresBanner open={disclosuresBannerOpen} handleClose={() => setDisclosuresBannerOpen(false)} />
			<RecurringPaymentAuthorization open={paymentBannerOpen} handleClose={() => setPaymentBannerOpen(false)} />
		</Container>
	)
}

export default SummaryPage
