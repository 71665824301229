import React, {ReactNode} from 'react'
import {useTheme} from "@mui/material";

interface Props {
	children: ReactNode
}
export const Wrapper = ({children}:Props) => {
	const theme = useTheme()
	return (
		<div style={theme.custom?.wrapper}>
			{children}
		</div>
	)
}
